import React, { type FormEvent, useEffect, useRef, useState } from 'react'
import { InputText } from 'components/Inputs'
import styled from 'styled-components'
import { IconSearch } from 'components/Icons'
import { useTranslation } from 'react-i18next'
import { changeSearchString } from 'actions'
import { observer } from 'mobx-react-lite'
import { appStore } from 'stores'

function onCtrlSlash(fn: () => void) {
	return (e: globalThis.KeyboardEvent) => {
		if (e.key === '/' && (e.ctrlKey || e.metaKey)) {
			fn()
		}
	}
}

const Key = styled.div`
	min-width: 28px;
	border: 2px solid var(--typography-heading);
	font-size: 16px;
	font-weight: 700;
	padding: 0 8px;
	border-radius: 8px;
	color: var(--typography-heading);
	opacity: 0.6;
`

const Root = styled.form`
	flex: 1;
	gap: 0 !important;
	input {
		border: 0;
	}
`

export const InputSearch = observer(() => {
	const { t } = useTranslation()
	const { isSearching } = appStore
	const [value, setValue] = useState('')
	const inputRef = useRef<null | HTMLInputElement>(null)

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		changeSearchString(value)
	}

	useEffect(() => {
		if(value && !isSearching && inputRef.current) inputRef.current.focus()
	}, [isSearching, value])

	useEffect(() => {
		window.addEventListener('keydown', onCtrlSlash(() => inputRef.current?.focus()))
		return () => {
			window.removeEventListener('keydown', onCtrlSlash(() => inputRef.current?.focus()))
		}
	}, [])

	return (
		<Root onSubmit={handleSubmit}>
			<IconSearch />
			<InputText
				name='search'
				value={value}
				onChange={({ value }) => { setValue(value) }}
				placeholder={<>{t('searchPlaceholder')} <Key>Ctrl</Key><Key>/</Key></>}
				disabled={isSearching}
				inputRef={inputRef}
				tabIndex={0}
			/>
			<input type='submit' style={{ display: 'none' }} />
		</Root>
	)
})
