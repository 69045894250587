export const TVA_OPTIONS = [
	{
		value: '',
		label: 'TVA'
	},
	{
		value: '0.2',
		label: '20%'
	},
	{
		value: '0.196',
		label: '19.6%'
	},
	{
		value: '0.13',
		label: '13%'
	},
	{
		value: '0.1',
		label: '10%'
	},
	{
		value: '0.085',
		label: '8.5%'
	},
	{
		value: '0.065',
		label: '6.5%'
	},
	{
		value: '0.06',
		label: '6%'
	},
	{
		value: '0.055',
		label: '5.5%'
	},
	{
		value: '0.04',
		label: '4%'
	},
	{
		value: '0.021',
		label: '2.1%'
	},
	{
		value: '0.09',
		label: '0.9%'
	},
	{
		value: '0',
		label: '0%'
	}
]
