import { api } from 'utils'
import { purchasesStore } from '../stores'
import { mapPurchasesDates } from '../utils'

export const getPurchasesDates = async () => {
	try {
		const { data: { purchases } } = await api.get('/purchases/stats?select=date')
		purchasesStore._purchasesDates = mapPurchasesDates(purchases)
	} catch(err) {
		console.log(err)
	}
}
