import { api } from 'utils'
import { purchasesStore } from '../stores'

export const sendReprocessRequest = async (id: string) => {
	try {
		purchasesStore.updatePurchase(id, { isProcessing: true, processed: false })
		await api.post('/purchases/reprocess', { ids: [id] })
	} catch(err) {
		console.log(err)
	}
}
