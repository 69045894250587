import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconTrash: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
			<path d="M4.36353 7H20.3635" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M10.3635 11V17" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.3635 11V17" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M5.36353 7L6.36353 19C6.36353 20.1046 7.25896 21 8.36353 21H16.3635C17.4681 21 18.3635 20.1046 18.3635 19L19.3635 7" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.36353 7V4C9.36353 3.44772 9.81124 3 10.3635 3H14.3635C14.9158 3 15.3635 3.44772 15.3635 4V7" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	)
}
