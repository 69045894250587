import { AreaSelectionContext, type IAreaSelectionContext } from 'modules/Purchases/contexts'
import { type IPurchase, type AreaSelectionOnChangeType } from 'modules/Purchases/types'
import React, { type MouseEvent, useContext, type CSSProperties } from 'react'
import { findMatch } from 'utils'

interface useAreaSelectionProps {
	// context: React.Context<IAreaSelectionContext | null>
	onChange?: AreaSelectionOnChangeType
	page?: number
	purchase?: IPurchase | null
	resizeFactor?: number
}

interface useAreaSelectionResponse {
	containerRef: React.MutableRefObject<HTMLDivElement | null>
	name: null | string
	isSelectionActive: boolean
	activateSelection: (name: string, index?: number) => void
	toggleSelectionVision: () => void
	Selection: () => JSX.Element
	onMouseMove: ((e: MouseEvent) => void) | undefined
	onMouseDown: ((e: MouseEvent) => void) | undefined
	onMouseUp: ((e: MouseEvent) => void) | undefined
	canvasRef: React.MutableRefObject<HTMLCanvasElement | null>
	currenTemplateValueFocused: string
	setCurrentTemplateValueFocused: React.Dispatch<React.SetStateAction<string>>
	containerProps: {
		onMouseMove: ((e: MouseEvent) => void) | undefined
		onMouseUp: ((e: MouseEvent) => void) | undefined
		onMouseDown: ((e: MouseEvent) => void) | undefined
		ref: React.MutableRefObject<HTMLDivElement | null>
		style: CSSProperties
	}
}

export const useAreaSelection = (props?: useAreaSelectionProps): useAreaSelectionResponse => {
	const {
		onChange: _onChange,
		page: _page,
		purchase: _purchase,
		resizeFactor: _resizeFactor
	} = props ?? {}
	const ctx = useContext(AreaSelectionContext)
	if(!ctx) throw new Error('error no context')
	const {
		style,
		setStyle,
		isSelectionActive,
		setIsSelectionActive,
		display,
		setDisplay,
		purchase,
		name,
		setName,
		_coords,
		resizeFactor,
		containerRef,
		onChange,
		page,
		canvasRef,
		currenTemplateValueFocused,
		setCurrentTemplateValueFocused,
		index
	} = ctx

	if(_resizeFactor) resizeFactor.current = _resizeFactor
	if(_onChange) onChange.current = _onChange
	if(_page) page.current = _page
	React.useEffect(() => { if(_purchase !== undefined) purchase.current = _purchase }, [_purchase])

	const Selection = () => (
		<div
			style={{
				border: '1px dashed blue',
				position: 'absolute',
				zIndex: 100,
				display,
				...style
			}}
		/>
	)

	function reCalc() {
		const { x1, x2, y1, y2 } = _coords.current
		const x3 = Math.min(x1, x2)
		const x4 = Math.max(x1, x2)
		const y3 = Math.min(y1, y2)
		const y4 = Math.max(y1, y2)
		setStyle({
			left: x3,
			top: y3,
			width: x4 - x3,
			height: y4 - y3
		})
	}

	const onMouseDown = isSelectionActive
		? (e: MouseEvent) => {
			if (display === 'none') setDisplay('block')
			const rect = containerRef.current?.getBoundingClientRect() ?? {
				left: 0,
				top: 0
			}
			_coords.current = {
				x1: e.clientX - rect.left,
				y1: e.clientY - rect.top,
				x2: e.clientX - rect.left,
				y2: e.clientY - rect.top
			}
			reCalc()
		}
		: undefined

	const onMouseMove = isSelectionActive
		? (e: MouseEvent) => {
			const rect = containerRef.current?.getBoundingClientRect() ?? {
				left: 0,
				top: 0
			}
			_coords.current = {
				..._coords.current,
				x2: e.clientX - rect.left,
				y2: e.clientY - rect.top
			}
			reCalc()
		}
		: undefined

	const onMouseUp = isSelectionActive
		? async (e: MouseEvent) => {
			const { left, top, width, height } = style
			setDisplay('none')
			if(onChange.current && name && purchase.current) {
				const value = findMatch(
					purchase.current.pages[page.current - 1].content,
					style,
					resizeFactor.current ?? 1
				)
				if(value) {
					const resize = resizeFactor.current ?? 1
					const topNormalized = value.y * resize - (value.height * resize / 1.5)
					const heightNormalized = value.height * resize
					onChange.current(
						{
							left,
							top: topNormalized,
							width,
							height: heightNormalized,
							page: page.current
						},
						value.str.split('\n')[0],
						name,
						index.current,
						() => {
							setIsSelectionActive(false)
							setName(null)
							index.current = 0
						}
					)
				}
			}
			setIsSelectionActive(false)
			setName(null)
			index.current = 0
		}
		: undefined

	const activateSelection = (_name: string, i?: number) => {
		if(isSelectionActive && name === _name) {
			setDisplay('none')
			setName(null)
			setIsSelectionActive(false)
		} else {
			if (display === 'block') setDisplay('none')
			index.current = i ?? 0
			setName(_name)
			setIsSelectionActive(true)
		}
	}

	const toggleSelectionVision = () => {
		setDisplay((prev) => {
			if (prev === 'block') return 'none'
			else return 'block'
		})
	}

	return {
		containerRef,
		name,
		isSelectionActive,
		activateSelection,
		toggleSelectionVision,
		Selection,
		onMouseDown,
		onMouseMove,
		onMouseUp,
		canvasRef,
		currenTemplateValueFocused,
		setCurrentTemplateValueFocused,
		containerProps: {
			onMouseDown,
			onMouseMove,
			onMouseUp,
			ref: containerRef,
			style: {
				position: 'relative'
			}
		}
	}
}
