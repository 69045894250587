import { cloneDeep } from 'lodash'

export const mapPurchaseToUpdate = (purchase: any) => {
	const _purchase = cloneDeep(purchase)

	delete _purchase?.string
	delete _purchase?.pages

	return _purchase
}
