import { type IPurchase } from '../types'

export const mapPurchaseFromApi = (data: IPurchase): IPurchase => {
	if(!data.tax.length) {
		data.tax.push({
			tax: '',
			tva: '',
			ht: ''
		})
	}

	return data
}
