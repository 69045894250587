import React from 'react'
import { IconButton } from '@mui/material'
import { ThemeContext } from 'theme'
import { IconMoon, IconSun } from 'components/Icons'

export const DarkMode = () => {
	const { theme, setMode } = React.useContext(ThemeContext)
	const toggleDarkMode = () => {
		if (theme === 'light') setMode('dark')
		else setMode('light')
	}
	return (
		<IconButton onClick={toggleDarkMode}>
			{theme === 'light' ? <IconMoon /> : <IconSun />}
		</IconButton>
	)
}
