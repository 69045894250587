import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconMoon: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path fillRule="evenodd" clipRule="evenodd" d="M12 2.99994C12.132 2.99994 12.263 2.99994 12.393 2.99994C9.74214 5.46311 9.22802 9.46779 11.1707 12.5207C13.1134 15.5736 16.9589 16.804 20.313 15.4459C18.5803 19.6151 14.0268 21.8552 9.66662 20.6836C5.30643 19.512 2.48907 15.2912 3.07943 10.8151C3.66978 6.33903 7.48513 2.99303 12 2.99194V2.99994Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M12 2.99994C12.132 2.99994 12.263 2.99994 12.393 2.99994C9.74214 5.46311 9.22802 9.46779 11.1707 12.5207C13.1134 15.5736 16.9589 16.804 20.313 15.4459C18.5803 19.6151 14.0268 21.8552 9.66662 20.6836C5.30643 19.512 2.48907 15.2912 3.07943 10.8151C3.66978 6.33903 7.48513 2.99303 12 2.99194V2.99994Z" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M17 4C17 5.10457 17.8954 6 19 6C17.8954 6 17 6.89543 17 8C17 6.89543 16.1046 6 15 6C16.1046 6 17 5.10457 17 4" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M17 4C17 5.10457 17.8954 6 19 6C17.8954 6 17 6.89543 17 8C17 6.89543 16.1046 6 15 6C16.1046 6 17 5.10457 17 4" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M19 10.25C18.5858 10.25 18.25 10.5858 18.25 11C18.25 11.4142 18.5858 11.75 19 11.75V10.25ZM21 11.75C21.4142 11.75 21.75 11.4142 21.75 11C21.75 10.5858 21.4142 10.25 21 10.25V11.75ZM20.75 10C20.75 9.58579 20.4142 9.25 20 9.25C19.5858 9.25 19.25 9.58579 19.25 10H20.75ZM19.25 12C19.25 12.4142 19.5858 12.75 20 12.75C20.4142 12.75 20.75 12.4142 20.75 12H19.25ZM19 11.75H21V10.25H19V11.75ZM19.25 10V12H20.75V10H19.25Z" fill={stroke}/>
			<path d="M19 10.25C18.5858 10.25 18.25 10.5858 18.25 11C18.25 11.4142 18.5858 11.75 19 11.75V10.25ZM21 11.75C21.4142 11.75 21.75 11.4142 21.75 11C21.75 10.5858 21.4142 10.25 21 10.25V11.75ZM20.75 10C20.75 9.58579 20.4142 9.25 20 9.25C19.5858 9.25 19.25 9.58579 19.25 10H20.75ZM19.25 12C19.25 12.4142 19.5858 12.75 20 12.75C20.4142 12.75 20.75 12.4142 20.75 12H19.25ZM19 11.75H21V10.25H19V11.75ZM19.25 10V12H20.75V10H19.25Z" fill="white" fillOpacity="0.2"/>
		</svg>
	)
}
