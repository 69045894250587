import { CircularProgress } from '@mui/material'
import { Show } from 'components'
import { observer } from 'mobx-react-lite'
import { purchasesStore } from 'modules/Purchases/stores'
import React from 'react'

export const Loader = observer(() => {
	const { purchases, isLoading } = purchasesStore
	return (
		<Show if={!purchases.length && isLoading} key='showPurchases'>
			<div className='flex w-full justify-center py-[26px]'>
				<CircularProgress />
			</div>
		</Show>
	)
})
