import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconPurchases: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path d="M14 3V7C14 7.55228 14.4477 8 15 8H19" stroke={iconColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21Z" stroke={iconColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12 14H9" stroke={iconColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.0041 11.172C12.7609 10.7316 11.3758 11.1549 10.5911 12.215C9.80644 13.2751 9.80619 14.7234 10.5905 15.7838C11.3748 16.8442 12.7597 17.268 14.0031 16.828" stroke={iconColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	)
}
