import React from 'react'
import { type InputSelectProps, InputSelect } from 'components'
import { type COORD } from 'modules/Purchases/types'

interface SelectInputProps extends Omit<InputSelectProps, 'onChange' | 'type'> {
	onChange: ({ value, name, coords, index }: { value: string, name: string, coords?: COORD, index: number }) => void
	index?: number
}

export const SelectInput: React.FC<SelectInputProps> = ({
	id,
	name,
	value,
	label,
	index,
	error,
	options,
	onChange
}) => {
	const handleChange = ({ name, value }: { name: string, value: string }) => {
		onChange({ name, value, index: index ?? 0 })
	}

	return (
		<div>
			<InputSelect
				id={id}
				name={name}
				value={value}
				error={error}
				label={label}
				options={options}
				onChange={handleChange}
			/>
		</div>
	)
}
