import styled from 'styled-components'

export const DataItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: flex-start;
`

export const IconWrapper = styled.button<{
	$width?: string
	$height?: string
	$background?: string
}>`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ $background }) => $background ?? 'linear-gradient(45deg, #7367F0 0%, #9E95F5 100%)'};
	height: ${({ $height }) => $height ?? '45px'};
	width: ${({ $width }) => $width ?? '45px'};
	border-radius: 6px;
`
