import { Button } from '@mui/material'
import styled from 'styled-components'

export const Root = styled.div`
	background: var(--background-card);
	border-radius: 0.75rem;
	box-shadow: var(--shadow-gray-SM);
	width: 100%;
	height: 62px;
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 12px 24px;
	& > div, & > form {
		display: flex;
		align-items: center;
		gap: 8px;
		&:last-of-type {
			margin-left: auto;
		}
	}
`

export const Settings = styled.div<{ $show: boolean }>`
	display: ${({ $show }) => $show ? 'flex' : 'none'};
	flex-direction: column;
	border-radius: 6px;
	background: var(--background-card);
	box-shadow: var(--shadow-gray-SM);
	position: absolute;
	z-index: 5;
	top: 60px;
	overflow: hidden;
`

export const SettingItem = styled(Button)`
	padding: 12px 24px;
	width: 100%;
	cursor: pointer;
	transition: background-color 0.1s ease;
	display: flex;
	align-items: center;
	justify-content: space-between !important;
	gap: 12px;
	&:hover {
		background-color: var(--opacity-color-primary-16)
	}
`

export const SettingsClose = styled.div<{ $show: boolean }>`
	display: ${({ $show }) => $show ? 'block' : 'none'};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
`
