import { makeAutoObservable } from 'mobx'
import { DEFAULT_EDIT_PURCHASE_FORM, DEFAULT_FORM_AREA_SELECTION, TVA_OPTIONS } from '../consts'
import { type formAreaSelectionType, type editFormStoreType, type COORD } from '../types'

export const editFormStore = makeAutoObservable({
	_open: false as boolean,
	_form: DEFAULT_EDIT_PURCHASE_FORM as editFormStoreType,
	_isSubmitting: false as boolean,
	_areaSelection: DEFAULT_FORM_AREA_SELECTION as formAreaSelectionType,
	_resizeFactor: 1.2 as number,

	get open(): boolean {
		return this._open
	},
	set open(value: boolean) {
		this._open = value
		if(!this._open && this._form.purchase) {
			this._form.purchase = null
		}
	},
	get areaSelection(): formAreaSelectionType {
		return this._areaSelection
	},
	set areaSelection(value: formAreaSelectionType) {
		this._areaSelection = value
	},
	get form(): editFormStoreType {
		return this._form
	},
	set form(value: editFormStoreType) {
		this._form = value
	},
	get isSubmitting(): boolean {
		return this._isSubmitting
	},
	set isSubmitting(value: boolean) {
		this._isSubmitting = value
	},
	get resizeFactor(): number {
		return this._resizeFactor
	},
	set resizeFactor(resizeFactor: number) {
		this._resizeFactor = resizeFactor
	},

	handleChangeTemplate({ coords, name, text, index }: { coords: COORD | null, name: string, text?: string, index: number }) {
		if(this._form.template) {
			const i = this._form.template.values.findIndex(value => value.name === name && value.index === index)
			if(coords) {
				if (i > -1) {
					this._form.template.values[i].coords = coords
					if(text) this._form.template.values[i].text = text
				} else {
					const newIndex = this._form.template.values.filter(value => value.name === name).length
					this._form.template.values.push({
						coords,
						name,
						index: newIndex,
						...(text && { text })
					})
				}
			} else {
				if(i > -1) {
					const arr = [...this._form.template.values]
					arr.splice(i, 1)
					this._form.template.values = [...arr]
				}
			}
		}
	},
	handlePurchaseChange({ name, value, index }: { name: string, value: string, index: number }) {
		if(this._form?.purchase) {
			if(name.split('.').length === 2) {
				(this._form.purchase.tax as any[])[index][name.split('.')[1]] = value
				const isTaxValid = TVA_OPTIONS.findIndex(item => this._form?.purchase?.tax[index].tax && item.value === this._form.purchase.tax[index].tax) > -1
				if(!isTaxValid && this._form.purchase.tax[index].tva && this._form.purchase.tax[index].ht) {
					const _tax = Number(this._form.purchase.tax[index].tva) / Number(this._form.purchase.tax[index].ht)
					const tax = Math.round((_tax + Number.EPSILON) * 100) / 100
					this._form.purchase.tax[index].tax = String(tax)
				}
			} else {
				(this._form.purchase as any)[name] = value
			}
		}
	},
	handleDeleteTvaLine(index: number) {
		if(this._form?.purchase?.tax.length && this._form?.purchase?.tax[index]) {
			this._form.purchase.tax.splice(index, 1)
		}
	},

	clear() {
		this._open = false as boolean
		this._form = DEFAULT_EDIT_PURCHASE_FORM as editFormStoreType
		this._areaSelection = DEFAULT_FORM_AREA_SELECTION as formAreaSelectionType
	}
})
