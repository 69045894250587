import { cloneDeep } from 'lodash'
import { type IDocumentTemplate } from '../types'

export const mapTemplateBeforeSubmit = (template: IDocumentTemplate | null): IDocumentTemplate | null => {
	if(!template) return null
	const _template = cloneDeep(template)
	_template.values = _template.values.filter(value => value.coords).map(value => {
		if(value.coords) {
			value.coords = {
				page: value.coords.page,
				top: value.coords.top,
				left: value.coords.left,
				width: value.coords.width,
				height: value.coords.height
			}
		}
		return value
	})
	delete _template._id

	return _template
}
