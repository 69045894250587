export const PAYMENT_WAY_OPTIONS = [
	{
		value: '',
		label: 'placeholder'
	},
	{
		value: 'income',
		label: 'income'
	},
	{
		value: 'outcome',
		label: 'outcome'
	},
	{
		value: 'income_marketplace',
		label: 'income_marketplace'
	},
	{
		value: 'outcome_marketplace',
		label: 'outcome_marketplace'
	}
]
