export const DEFAULT_FORM_AREA_SELECTION = {
	style: {
		top: 0,
		left: 0,
		width: 0,
		height: 0
	},
	containerRef: null,
	isSelectionActive: false,
	display: 'none'
}
