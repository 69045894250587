import React from 'react'
import { IconButton } from '@mui/material'
import { IconNotification } from 'components/Icons'

export const Notifications = () => {
	return (
		<IconButton className='relative'>
			<IconNotification />
		</IconButton>
	)
}
