import { makeAutoObservable } from 'mobx'
import { type IRegisterForm, type IRegisterError } from '../types'
import { type InputChangeProps } from 'types'
import { DEFAULT_REGISTER_ERROR, DEFAULT_REGISTER_FORM } from '../constants'

export const registerStore = makeAutoObservable({
	_form: DEFAULT_REGISTER_FORM as IRegisterForm,
	_error: DEFAULT_REGISTER_ERROR as IRegisterError,
	_isSubmitting: false as boolean,

	get form(): IRegisterForm {
		return this._form
	},
	get error(): IRegisterError {
		return this._error
	},
	get isSubmitting(): boolean {
		return this._isSubmitting
	},
	set isSubmitting(value: boolean) {
		this._isSubmitting = value
	},
	changeField(data: InputChangeProps) {
		(this._form as any)[data.name] = data.value
	},
	changeError(data: InputChangeProps) {
		(this._error as any)[data.name] = data.value
	},
	clear() {
		this._form = DEFAULT_REGISTER_FORM as IRegisterForm
		this._error = DEFAULT_REGISTER_ERROR as IRegisterError
	}
})
