import React, { useContext } from 'react'
import type { IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconClose: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
		>
			<path
				d="M15.3549 5.70367L5.35486 15.7037"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.3549 5.70367L5.35486 15.7037"
				stroke="white"
				strokeOpacity="0.5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.35486 5.70367L15.3549 15.7037"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.35486 5.70367L15.3549 15.7037"
				stroke="white"
				strokeOpacity="0.5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
