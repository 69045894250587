import React, { useEffect } from 'react'
import { Root } from './styled'
import { EditData, Header, Modal, PurchasesList, PurchasesSummary, DeleteModal, CalendarModal } from './components'
import { mount, setResizeFactor, unmount } from './actions'
import { useMediaQuery } from '@mui/material'

export const Purchases = () => {
	const minHeight1280 = useMediaQuery('( min-height: 1280px )')
	const maxWidth1280 = useMediaQuery('( max-width: 1280px )')
	const maxWidth768 = useMediaQuery('( max-width: 768px )')

	React.useEffect(() => {
		setResizeFactor(minHeight1280 && !maxWidth1280 ? 1.2 : maxWidth768 ? 0.7 : maxWidth1280 ? 0.9 : 1)
	}, [minHeight1280, maxWidth1280, maxWidth768])

	useEffect(() => {
		mount()
		return () => {
			unmount()
		}
	}, [])

	return (
		<Root>
			<DeleteModal />
			<Modal />
			<EditData />
			<CalendarModal />

			<Header />
			<PurchasesList />
			<PurchasesSummary />
		</Root>
	)
}
