import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  gap: 26px;
`
export const Content = styled.div<{ $noPaddingTop?: boolean }>`
  display: flex;
  flex-direction: column;
  background: var(--background-body);
  flex: 1;
  padding: 26px;
  padding-left: 0;
  padding-top: ${({ $noPaddingTop }) => $noPaddingTop ? '0' : '26px'};
  gap: 26px;
`

export const Page = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`
