import React, { useContext, useState, useEffect } from 'react'
import { GlobalLoader, PageWrapper, PrivateRoute, Show } from 'components'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Dashboard, Login, Purchases, Register, Settings, Support } from 'modules'
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from 'theme'
import 'i18n/config'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import 'moment/locale/fr'
import 'moment/locale/ru'
import { api } from 'utils'
import Cookies from 'js-cookie'
import { AnimatePresence } from 'framer-motion'

function App() {
	const { theme } = useContext(ThemeContext)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		(async () => {
			const { data } = await api.get('/auth')
			if(data.user) {
				Cookies.set('user', JSON.stringify(data.user))
				setTimeout(() => {
					setIsLoading(false)
				}, 1000)
			}
		})().catch(err => {
			console.log(err)
			Cookies.remove('user')
			setTimeout(() => {
				setIsLoading(false)
			}, 1000)
		})
	}, [])

	return (
		<div className="App">
			<AnimatePresence>
				{isLoading && <GlobalLoader />}
			</AnimatePresence>
			<Show if={!isLoading}>
				<BrowserRouter>
					<Routes>
						<Route path='/' element={<PrivateRoute element={<PageWrapper />} />}>
							<Route index element={<Dashboard />} />
							<Route path='/purchases' element={<Purchases />} />
							<Route path='/support' element={<Support />} />
							<Route path='/settings' element={<Settings />} />
						</Route>
						<Route path='/login' element={<Login />} />
						<Route path='/register' element={<Register />} />
						<Route path='*' element={<Navigate to='/404' />} />
					</Routes>
				</BrowserRouter>
				<ToastContainer theme={theme} />
			</Show>
		</div>
	)
}

export default App
