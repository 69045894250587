import axios from 'axios'
import { ROUTES } from 'consts/ROUTES'
import Cookies from 'js-cookie'

const api = axios.create({
	baseURL: process.env.REACT_APP_BACK_URL ?? '',
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
})

api.interceptors.response.use(response => {
	return response
}, error => {
	if (error.response.status === 401) {
		Cookies.remove('user')
		Cookies.remove('sid')
		if(!['/', '/login', '/register'].includes(window.location.pathname)) window.location.href = ROUTES.login
	}
	throw error
})

export default api
