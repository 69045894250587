import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { AnimatePresence } from 'framer-motion'
import { editFormStore, purchasesStore } from 'modules/Purchases/stores'
import { DateItem } from './DateItem'
import { appStore } from 'stores'
import { getPurchases } from 'modules/Purchases/actions/getPurchases'
import { Button, CircularProgress } from '@mui/material'
import { ArrowDownward } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { handleGetMorePurchases, setMetaSearch } from 'modules/Purchases/actions'

export const List = observer(() => {
	const { t } = useTranslation('purchases')
	const { searchString } = appStore
	const { purchasesSortedDate, meta, scrollTo } = purchasesStore
	const { resizeFactor } = editFormStore
	const initialRender = useRef(true)

	useEffect(() => {
		if(initialRender.current) {
			initialRender.current = false
		} else {
			setMetaSearch(searchString)
			getPurchases().catch(err => { console.log(err) })
		}
	}, [searchString])

	const purchases = purchasesSortedDate(resizeFactor)

	if(scrollTo) {
		const element = document.getElementById(scrollTo)
		if(element) {
			setTimeout(() => {
				element.scrollIntoView()
				purchasesStore.scrollTo = undefined
			}, 0)
		}
	}

	return <AnimatePresence>
		{purchases.map(({ month, year, days }, index) =>
			<DateItem key={`purchases-${month}-${year}`} days={days} month={month} year={year} showMonth={index === 0 || purchases[index - 1].month !== month} />
		)}
		<Button
			id='purchasesButtonGetMore'
			className='self-center'
			variant='contained'
			startIcon={purchasesStore.isLoading ? <CircularProgress size='20px' color='inherit' /> : <ArrowDownward />}
			onClick={async () => { await handleGetMorePurchases() }}
			sx={(meta.totalPages > meta.page) || (purchasesStore.isLoading && purchasesStore.purchases.length) ? undefined : { opacity: 0, pointerEvents: 'none' }}
		>
			{t('getMorePurchases')}
		</Button>
	</AnimatePresence>
})
