import React from 'react'
import { getUser } from 'actions'
import { Navigate, useLocation } from 'react-router-dom'
import { LandingPage } from 'modules'

type PrivateRouteProps = {
	element?: React.ReactElement
	children?: never
} | {
	children?: React.ReactNode
	element?: never
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, children }) => {
	const location = useLocation()
	const user = getUser()
	if(location.pathname === '/' && !user) return <LandingPage />
	else if(user) return element
	else return <Navigate to="/login" state={{ from: location }} />
}
