export const DUMMY_FORM_PURCHASE = {
	title: '',
	totalTTC: '',
	totalHT: '',
	totalTVA: '',
	tax: [],
	date: '',
	invoiceNumber: '',
	paymentWay: '',
	originalName: '',
	documentEntity: ''
}
