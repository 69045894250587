import { api, socket } from 'utils'
import { loginStore } from '../stores'
import { changeError } from './changeError'
import { setIsSubmitting } from './setIsSubmitting'
import { type NavigateFunction } from 'react-router'
import Cookies from 'js-cookie'

export const login = async (navigate: NavigateFunction) => {
	setIsSubmitting(true)
	try {
		const { form } = loginStore
		let hadError = false
		for (const key of Object.keys(form)) {
			if(key === 'rememberMe') {
				continue
			}
			if(!(form as any)[key]) {
				hadError = true
				changeError({ name: key, value: 'value_is_empty' })
			}
		}
		if(!hadError) {
			const res = await api.post('/auth', form)
			if(res.status === 200 && res.data.user) {
				Cookies.set('user', JSON.stringify(res.data.user))
				socket.emit('login', res.data.user._id)
				navigate('/')
			}
		}
		setIsSubmitting(false)
	} catch(err: any) {
		setIsSubmitting(false)
		const { error, message } = err?.response?.data
		if(error && message) {
			changeError({ name: error, value: message })
		}
	}
}
