import React, { useContext } from 'react'
import { RegisterForm } from './components'
import { mount, unmount } from './actions'
import registerBG from 'assets/img/registerBG.jpg'
import registerBGdark from 'assets/img/registerBG-dark.jpg'
import { ThemeContext } from 'theme'
import styled from 'styled-components'
import { useMediaQuery } from '@mui/material'

const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	overflow: hidden;
	flex: 3;
	width: 100%;
	max-height: calc(100svh - 64px);
	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@media screen and ( max-width: 1100px ) {
		display: none;
	}
`

export const Register = () => {
	const { theme } = useContext(ThemeContext)
	const variant = useMediaQuery('screen and ( max-width: 1100px )') ? 'card' : 'full'
	React.useEffect(() => {
		mount()
		return () => {
			unmount()
		}
	}, [])

	return (
		<div className={`flex h-full items-center justify-center ${variant === 'full' ? 'bg-[var(--background-card)]' : ''}  p-[32px]`}>
			<div className='flex w-full max-w-[1440px] items-center justify-center'>
				<ImageWrapper><img src={theme === 'dark' ? registerBGdark : registerBG} /></ImageWrapper>
				<RegisterForm variant={variant} />
			</div>
		</div>
	)
}
