import React, { useEffect, useState } from 'react'
import CheckboxMui from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'

const Root = styled.div``

interface InputCheckboxProps {
	className?: string
	value: boolean
	label?: React.ReactNode
	name: string
	disabled?: boolean
	debounce?: number
	onChange: ({ name, value }: { name: string, value: boolean }) => void
}

export const InputCheckbox: React.FC<InputCheckboxProps> = ({
	value: _value,
	debounce = 0,
	className,
	name,
	label,
	disabled,
	onChange
}) => {
	const [value, setValue] = useState(_value)
	const [debouncedValue] = useDebounce(value, debounce)

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setValue(checked)
	}

	useEffect(() => {
		if(onChange && debouncedValue !== _value) {
			onChange({ name, value: debouncedValue })
		}
	}, [onChange, debouncedValue, name])

	return (
		<Root className={className}>
			<FormControlLabel
				disabled={disabled}
				control={
					<CheckboxMui
						checked={value}
						onChange={handleChange}
					/>
				}
				label={label}
			/>
		</Root>
	)
}
