import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconNotification: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
			<path d="M11.2741 5.41667C11.2741 4.22005 12.2442 3.25 13.4408 3.25C14.6374 3.25 15.6075 4.22005 15.6075 5.41667C18.1427 6.61543 19.8093 9.11543 19.9408 11.9167V15.1667C20.1061 16.5325 20.9103 17.7387 22.1075 18.4167H4.77414C5.97135 17.7387 6.77547 16.5325 6.94081 15.1667V11.9167C7.07229 9.11543 8.73896 6.61543 11.2741 5.41667" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M11.2741 5.41667C11.2741 4.22005 12.2442 3.25 13.4408 3.25C14.6374 3.25 15.6075 4.22005 15.6075 5.41667C18.1427 6.61543 19.8093 9.11543 19.9408 11.9167V15.1667C20.1061 16.5325 20.9103 17.7387 22.1075 18.4167H4.77414C5.97135 17.7387 6.77547 16.5325 6.94081 15.1667V11.9167C7.07229 9.11543 8.73896 6.61543 11.2741 5.41667" stroke="white" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M10.1908 18.4167V19.5C10.1908 21.2949 11.6459 22.75 13.4408 22.75C15.2357 22.75 16.6908 21.2949 16.6908 19.5V18.4167" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M10.1908 18.4167V19.5C10.1908 21.2949 11.6459 22.75 13.4408 22.75C15.2357 22.75 16.6908 21.2949 16.6908 19.5V18.4167" stroke="white" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	)
}
