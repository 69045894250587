import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconEyeOff: React.FC<IconProps> = ({
	stroke
}) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
			<path d="M3.5 3L21.5 21" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M3.5 3L21.5 21" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M11.084 10.587C10.3025 11.3679 10.302 12.6345 11.083 13.416C11.8639 14.1975 13.1305 14.1979 13.912 13.417" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M11.084 10.587C10.3025 11.3679 10.302 12.6345 11.083 13.416C11.8639 14.1975 13.1305 14.1979 13.912 13.417" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.65676 4.64392C9.25852 4.75784 9.02804 5.17303 9.14196 5.57127C9.25588 5.96951 9.67107 6.2 10.0693 6.08607L9.65676 4.64392ZM12.5 5L12.4974 5.75H12.5V5ZM22.5 12L23.1512 12.3722C23.283 12.1416 23.283 11.8585 23.1512 11.6279L22.5 12ZM19.4463 14.9789C19.1651 15.2831 19.1838 15.7576 19.488 16.0388C19.7922 16.3199 20.2667 16.3012 20.5478 15.9971L19.4463 14.9789ZM18.2764 17.9708C18.6198 17.7392 18.7104 17.273 18.4788 16.9296C18.2472 16.5862 17.781 16.4956 17.4376 16.7272L18.2764 17.9708ZM2.5 12L1.84887 11.6278C1.71706 11.8584 1.71704 12.1415 1.84883 12.3721L2.5 12ZM7.55301 7.27968C7.89581 7.04716 7.9852 6.58078 7.75268 6.23799C7.52017 5.89519 7.05378 5.80579 6.71099 6.03831L7.55301 7.27968ZM10.0693 6.08607C10.8588 5.86023 11.6762 5.74708 12.4974 5.74999L12.5027 4.25C11.5402 4.24659 10.5821 4.37921 9.65676 4.64392L10.0693 6.08607ZM12.5 5.75C16.14 5.75 19.2632 7.84743 21.8489 12.3721L23.1512 11.6279C20.4029 6.81857 16.86 4.25 12.5 4.25V5.75ZM21.8489 11.6278C21.0937 12.949 20.292 14.0639 19.4463 14.9789L20.5478 15.9971C21.4841 14.9841 22.3504 13.773 23.1512 12.3722L21.8489 11.6278ZM17.4376 16.7272C15.9284 17.7451 14.29 18.25 12.5 18.25V19.75C14.594 19.75 16.5236 19.1529 18.2764 17.9708L17.4376 16.7272ZM12.5 18.25C8.86001 18.25 5.73685 16.1526 3.15117 11.6279L1.84883 12.3721C4.59715 17.1814 8.13999 19.75 12.5 19.75V18.25ZM3.15113 12.3722C4.48114 10.0454 5.95439 8.36403 7.55301 7.27968L6.71099 6.03831C4.87161 7.28597 3.25686 9.16458 1.84887 11.6278L3.15113 12.3722Z" fill={stroke}/>
			<path d="M9.65676 4.64392C9.25852 4.75784 9.02804 5.17303 9.14196 5.57127C9.25588 5.96951 9.67107 6.2 10.0693 6.08607L9.65676 4.64392ZM12.5 5L12.4974 5.75H12.5V5ZM22.5 12L23.1512 12.3722C23.283 12.1416 23.283 11.8585 23.1512 11.6279L22.5 12ZM19.4463 14.9789C19.1651 15.2831 19.1838 15.7576 19.488 16.0388C19.7922 16.3199 20.2667 16.3012 20.5478 15.9971L19.4463 14.9789ZM18.2764 17.9708C18.6198 17.7392 18.7104 17.273 18.4788 16.9296C18.2472 16.5862 17.781 16.4956 17.4376 16.7272L18.2764 17.9708ZM2.5 12L1.84887 11.6278C1.71706 11.8584 1.71704 12.1415 1.84883 12.3721L2.5 12ZM7.55301 7.27968C7.89581 7.04716 7.9852 6.58078 7.75268 6.23799C7.52017 5.89519 7.05378 5.80579 6.71099 6.03831L7.55301 7.27968ZM10.0693 6.08607C10.8588 5.86023 11.6762 5.74708 12.4974 5.74999L12.5027 4.25C11.5402 4.24659 10.5821 4.37921 9.65676 4.64392L10.0693 6.08607ZM12.5 5.75C16.14 5.75 19.2632 7.84743 21.8489 12.3721L23.1512 11.6279C20.4029 6.81857 16.86 4.25 12.5 4.25V5.75ZM21.8489 11.6278C21.0937 12.949 20.292 14.0639 19.4463 14.9789L20.5478 15.9971C21.4841 14.9841 22.3504 13.773 23.1512 12.3722L21.8489 11.6278ZM17.4376 16.7272C15.9284 17.7451 14.29 18.25 12.5 18.25V19.75C14.594 19.75 16.5236 19.1529 18.2764 17.9708L17.4376 16.7272ZM12.5 18.25C8.86001 18.25 5.73685 16.1526 3.15117 11.6279L1.84883 12.3721C4.59715 17.1814 8.13999 19.75 12.5 19.75V18.25ZM3.15113 12.3722C4.48114 10.0454 5.95439 8.36403 7.55301 7.27968L6.71099 6.03831C4.87161 7.28597 3.25686 9.16458 1.84887 11.6278L3.15113 12.3722Z" fill="white" fillOpacity="0.2"/>
		</svg>
	)
}
