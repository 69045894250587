import React from 'react'
import { Calendar, Modal } from 'components'
import { observer } from 'mobx-react-lite'
import { handleGetMorePurchases, toggleCalendarModal } from 'modules/Purchases/actions'
import { purchasesStore } from 'modules/Purchases/stores'
import { type View } from 'react-calendar/dist/cjs/shared/types'

const mapDate = (date: Date) => {
	return {
		day: date.getDate(),
		month: date.getMonth(),
		year: date.getFullYear()
	}
}

export const CalendarModal = observer(() => {
	const { calendarModal, purchasesDates, purchases } = purchasesStore

	function tileClassName({ date, view }: { date: Date, view: string }) {
		if (view === 'month') {
			if (purchasesDates.find(dDate => {
				if(dDate.year === date.getFullYear() &&
					dDate.month === date.getMonth() &&
					dDate.days.includes(date.getDate())
				) return dDate
				return null
			})) {
				return 'myClassName'
			} else return 'pointer-events-none opacity-25'
		}
		if(view === 'year') {
			if (purchasesDates.find(dDate => {
				if(dDate.year === date.getFullYear() && dDate.month === date.getMonth()) {
					return dDate
				}
				return null
			})) {
				return 'myClassName'
			} else return 'pointer-events-none opacity-25'
		}
	}

	const onClickDay = async (value: Date, event: React.MouseEvent<HTMLButtonElement>, secondaryCall?: boolean) => {
		purchasesStore.calendarModal = false
		const { day, month, year } = mapDate(value)
		const id = `day-${day}-${month}-${year}`
		const element = document.getElementById(id)
		if(element) {
			element.scrollIntoView()
		} else {
			document.getElementById('purchasesButtonGetMore')?.scrollIntoView()
			value.setDate(1)
			await handleGetMorePurchases({ findDate: value.toLocaleDateString() })
			purchasesStore.scrollTo = id
		}
	}

	const onClickMonth = async (value: Date, event: React.MouseEvent<HTMLButtonElement>, secondaryCall?: boolean) => {
		purchasesStore.calendarModal = false
		if(typeof calendarModal === 'string' && calendarModal.split('|')[0] === 'year') {
			const { month, year } = mapDate(value)
			const id = `month-${month}-${year}`
			const element = document.getElementById(id)
			if(element) {
				element.scrollIntoView()
			} else {
				document.getElementById('purchasesButtonGetMore')?.scrollIntoView()
				await handleGetMorePurchases({ findMonth: value.toLocaleDateString() })
				const day = purchasesDates.find(date => date.month === month && date.year === year)?.days[0] ?? undefined
				purchasesStore.scrollTo = day ? `day-${day}-${month}-${year}` : id
			}
		}
	}

	return (
		<Modal open={Boolean(calendarModal)} onClose={toggleCalendarModal}>
			<Calendar
				value={typeof calendarModal === 'string' ? new Date(calendarModal.split('|')[1]) : null}
				view={typeof calendarModal === 'string' ? calendarModal.split('|')[0] as View || undefined : undefined}
				tileClassName={tileClassName}
				onClickDay={onClickDay}
				onClickMonth={onClickMonth}
			/>
		</Modal>
	)
})
