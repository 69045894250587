import { makeAutoObservable } from 'mobx'

interface RejectedFileType { id: string, originalName: string }
interface AcceptedFileType { ready: boolean, originalName: string, id?: string }

const DEFAULT_PURCHASE_FORM = {
	file: null
}

interface PurchaseFormType {
	file: null | Blob
}

export const purchaseFormStore = makeAutoObservable({
	_open: false as boolean,
	_isFileLoading: false as boolean,
	_form: DEFAULT_PURCHASE_FORM as PurchaseFormType,
	_acceptedFiles: [] as AcceptedFileType[],
	_rejectedFiles: [] as RejectedFileType[],

	get open(): boolean {
		return this._open
	},
	set open(value: boolean) {
		this._open = value
	},
	get isFileLoading(): boolean {
		return this._isFileLoading
	},
	set isFileLoading(value: boolean) {
		this._isFileLoading = value
	},
	get form(): PurchaseFormType {
		return this._form
	},
	set form(value: PurchaseFormType) {
		this._form = value
	},
	get acceptedFiles(): AcceptedFileType[] {
		return this._acceptedFiles
	},
	set acceptedFiles(value: AcceptedFileType[]) {
		this._acceptedFiles = value
	},
	get rejectedFiles(): RejectedFileType[] {
		return this._rejectedFiles
	},
	set rejectedFiles(value: RejectedFileType[]) {
		this._rejectedFiles = value
	},
	get isDisabledToSubmit(): boolean {
		if(!this._acceptedFiles.length) return true
		const ready = this._acceptedFiles.filter(file => file.ready && file?.id)
		return ready.length !== this._acceptedFiles.length
	},
	get acceptedIds(): string[] {
		return this._acceptedFiles.map(file => file.id ?? '').filter(file => file)
	},
	clear(options?: { ignoreOpen: boolean }) {
		this._isFileLoading = false as boolean
		this._form = DEFAULT_PURCHASE_FORM as PurchaseFormType
		this._acceptedFiles = [] as AcceptedFileType[]
		this._rejectedFiles = [] as RejectedFileType[]
		if(!options?.ignoreOpen) this._open = false as boolean
	}
})
