import moment from 'moment'
import { cloneDeep } from 'lodash'
import { type IPurchase, type IPurchasesDates } from '../types'

export const mapPurchasesDates = (purchases: IPurchase[]): IPurchasesDates[] => {
	const purchasesCloned = cloneDeep(purchases)
	const _purchases = purchasesCloned.sort((a, b) => moment(a.date).isBefore(moment(b.date)) ? 1 : -1)
	let arr = [] as IPurchasesDates[]
	for(const purchase of _purchases) {
		if(purchase.date) {
			const day = moment(purchase.date).get('date')
			const month = moment(purchase.date).get('month')
			const year = moment(purchase.date).get('year')
			const index = arr.findIndex(item => item.month === month && item.year === year)
			if(index > -1) {
				arr[index].days.push(day)
			} else {
				arr.push({
					month,
					year,
					days: [day]
				})
			}
		}
	}
	arr = arr.sort((a, b) => {
		if(a.year < b.year) return 1
		else if(a.month < b.month) return 1
		else return -1
	})
	for(const item of arr) {
		item.days = item.days.sort((a, b) => a < b ? 1 : -1)
	}

	return arr
}
