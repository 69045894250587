import React from 'react'

interface MenuSectionProps {
	title?: string
	className?: string
	children: React.ReactNode
}

export const MenuSection: React.FC<MenuSectionProps> = ({
	title,
	className,
	children
}) => {
	return (
		<div className={className}>
			{title && <p className='px-[30px] pb-[5px] pt-[19px] text-left text-[11px] uppercase leading-[14px] text-[var(--typography-muted)] opacity-0 lg2:opacity-100'>{title}</p>}
			<div className='flex flex-col gap-[8px]'>
				{children}
			</div>
		</div>
	)
}
