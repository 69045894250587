import { Button } from '@mui/material'
import { Heading3, Heading4, Paragraph } from 'components'
import React from 'react'
import { Link } from 'react-router-dom'

export const LandingPage = () => {
	return (
		<div className='flex h-full w-full flex-col items-center justify-center gap-[16px]'>
			<Heading3>Welcome to ZeEnergy!</Heading3>
			<Heading4>THIS PAGE IS IN DEVELOPMENT AT THE MOMENT</Heading4>
			<Paragraph>To proceed please login or register</Paragraph>
			<div className='flex items-center gap-[12px]'>
				<Link to='/login'><Button variant='contained'>LOG IN</Button></Link>
				<Link to='/register'><Button variant='contained'>REGISTER</Button></Link>
			</div>
		</div>
	)
}
