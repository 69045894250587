import styled from 'styled-components'

export const Title = styled.h1`
	color: var(--typography-heading);
	font-size: 22px;
	font-weight: 700;
	line-height: 24px;
`

export const Heading3 = styled.h3`
	color: var(--typography-heading);
	font-family: var(--basic-typography-h3-heading-font-family);
	font-size: var(--basic-typography-h3-heading-font-size);
	font-style: var(--basic-typography-h3-heading-font-style);
	font-weight: var(--basic-typography-h3-heading-font-weight);
	letter-spacing: var(--basic-typography-h3-heading-letter-spacing);
	line-height: var(--basic-typography-h3-heading-line-height);
`

export const Heading4 = styled.h4`
	color: var(--typography-heading);
	font-family: var(--basic-typography-h4-heading-font-family);
	font-size: var(--basic-typography-h4-heading-font-size);
	font-style: var(--basic-typography-h4-heading-font-style);
	font-weight: var(--basic-typography-h4-heading-font-weight);
	letter-spacing: var(--basic-typography-h4-heading-letter-spacing);
	line-height: var(--basic-typography-h4-heading-line-height);
`

export const Paragraph = styled.p<{ color?: string, $weight?: 'bold' | 'semi-bold' }>`
	color: ${({ color }) => color ?? 'var(--typography-heading)'};
	font-family: var(--basic-typography-paragraph-font-family);
	font-size: var(--basic-typography-paragraph-font-size);
	font-style: var(--basic-typography-paragraph-font-style);
	font-weight: var(--basic-typography-paragraph${({ $weight }) => $weight ? `-${$weight}` : ''}-font-weight);
`

export const ParagraphSemiBold = styled(Paragraph)`
	font-weight: var(--basic-typography-paragraph-semi-bold-font-weight);
`

export const Label = styled.label<{ color?: string }>`
	color: ${({ color }) => color ?? 'var(--typography-heading)'};
	font-family: var(--form-input-3-label-sm-font-family);
	font-size: var(--form-input-3-label-sm-font-size);
	font-style: var(--form-input-3-label-sm-font-style);
	font-weight: var(--form-input-3-label-sm-font-weight);
	letter-spacing: var(--form-input-3-label-sm-letter-spacing);
	line-height: var(--form-input-3-label-sm-line-height);
`

export const ErrorParagraph = styled(Paragraph)`
	color: var(--color-danger);
`

export const THeader1 = styled.p<{ color?: string, $danger?: boolean }>`
	color: ${({ $danger }) => $danger ? 'var(--color-danger)' : 'var(--color-high)'};
	${({ color }) => color ? `color: ${color};` : ''}
	font-family: var(--table-header-1-table-header-font-family);
	font-size: var(--table-header-1-table-header-font-size);
	font-style: var(--table-header-1-table-header-font-style);
	font-weight: var(--table-header-1-table-header-font-weight);
	letter-spacing: var(--table-header-1-table-header-letter-spacing);
	line-height: var(--table-header-1-table-header-line-height);
`

export const Caption = styled.p<{ color?: string }>`
	font-family: var(--basic-typography-caption-font-family);
	font-size: var(--basic-typography-caption-font-size);
	font-style: var(--basic-typography-caption-font-style);
	font-weight: var(--basic-typography-caption-font-weight);
	letter-spacing: var(--basic-typography-caption-letter-spacing);
	line-height: var(--basic-typography-caption-line-height);
	color: ${({ color }) => color ? color + ';' : ''};
`

export const ParagraphLeading = styled.p`
	font-size: var(--basic-typography-paragraph-lead-font-size);
	font-weight: var(--basic-typography-paragraph-lead-font-weight);
	line-height: var(--basic-typography-paragraph-lead-line-height);
	letter-spacing: var(--basic-typography-paragraph-lead-letter-spacing);
`

export const Display4 = styled.p<{ color?: string }>`
	font-family: var(--basic-typography-display-4-font-family);
	font-size: var(--basic-typography-display-4-font-size);
	font-style: var(--basic-typography-display-4-font-style);
	font-weight: var(--basic-typography-display-4-font-weight);
	letter-spacing: var(--basic-typography-display-4-letter-spacing);
	line-height: var(--basic-typography-display-4-line-height);
	${({ color }) => color ? `color: ${color};` : ''}};
`
