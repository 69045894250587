import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconFile: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
			<path d="M14.5 3.5V7.5C14.5 8.05228 14.9477 8.5 15.5 8.5H19.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M17.5 21.5H7.5C6.39543 21.5 5.5 20.6046 5.5 19.5V5.5C5.5 4.39543 6.39543 3.5 7.5 3.5H14.5L19.5 8.5V19.5C19.5 20.6046 18.6046 21.5 17.5 21.5Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.5 7.5H10.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.5 13.5H15.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M13.5 17.5H15.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	)
}
