import moment from 'moment'
import { editFormStore } from '../stores'
import { type IDocumentTemplate, type COORD } from '../types'
import { runInAction } from 'mobx'
export const getNum = (value: string): number | undefined => {
	const _value = value
		// eslint-disable-next-line no-useless-escape
		.match(/[0-9,\.-]+/g)
		?.join('')
		.replaceAll(',', '.')
		.split('.')
	const afterDot = _value?.pop()
	const num = _value?.join('') + '.' + afterDot
	if (num && !isNaN(Number(num))) return Number(num)
	return undefined
}

export const handleTemplateValue = ({ name, value, coords, index }: { name: string, value: string, coords?: COORD | null, index: number }, cb?: () => void) => {
	switch(name) {
		case 'totalTTC':
		case 'totalTVA':
		case 'totalHT':
		case 'tax.ht':
		case 'tax.tva':
			value = String(getNum(value))
			break
		case 'invoiceNumber':
			value = value.split(' ').pop() ?? value
			break
		case 'date':
			value = moment(value, 'DD/MM/YYYY').toISOString(true)
			break
		default:
			break
	}
	if(coords) {
		const resizeFactor = editFormStore.resizeFactor
		coords = {
			top: coords.top / resizeFactor,
			left: coords.left / resizeFactor,
			width: coords.width / resizeFactor,
			height: coords.height / resizeFactor,
			page: coords.page
		}
	} else {
		coords = null
	}
	if(name === 'paymentWay') {
		runInAction(() => {
			if(editFormStore.form.template?.paymentWay !== undefined) {
				editFormStore.form.template.paymentWay = value as IDocumentTemplate['paymentWay']
			}
		})
	}
	editFormStore.handleChangeTemplate({ coords, name, text: ['title', 'documentEntity'].includes(name) ? value : undefined, index })
	editFormStore.handlePurchaseChange({ name, value, index })
	if(cb) {
		cb()
	}
}
