import React from 'react'
import { type COORD } from 'modules/Purchases/types'
import { useAreaSelection } from 'hooks'

export const TemplateValue: React.FC<{
	coords: COORD
	name: string
	resizeFactor: number
	index: number
}> = ({ coords, name, resizeFactor, index }) => {
	const { currenTemplateValueFocused } = useAreaSelection()
	const focusedName = currenTemplateValueFocused.split(':')[0] ?? ''
	const focusedIndex = currenTemplateValueFocused.split(':')[1] ?? ''
	return (
		<div className='pointer-events-auto absolute p-[16px] transition-all' style={{
			top: (coords.top * resizeFactor) - 16,
			left: (coords.left * resizeFactor) - 16,
			width: (coords.width * resizeFactor) + 32,
			height: (coords.height * resizeFactor) + 32,
			opacity: currenTemplateValueFocused ? focusedName === name && Number(focusedIndex) === index ? 1 : 0.1 : 0.8
		}}>
			<div className='h-full w-full border-[2px] border-blue-500'>
			</div>
		</div>
	)
}
