import React from 'react'
import { Root, Image } from './styled'
import defaultAvatar from 'assets/img/logo.svg'

interface AvatarProps {
	src?: string
}
export const Avatar: React.FC<AvatarProps> = ({
	src
}) => {
	return (
		<Root>
			<Image className={src ? '' : '!object-contain px-1 pt-1'} src={src ?? defaultAvatar} />
		</Root>
	)
}
