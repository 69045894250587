import React from 'react'
import { Modal as CustomModal, Heading4, Paragraph, Show } from 'components'
import { observer } from 'mobx-react-lite'
import { purchaseFormStore } from 'modules/Purchases/stores/purchaseFormStore'
import { closeFormModal, handleFileLoad } from 'modules/Purchases/actions'
import { useTranslation } from 'react-i18next'
import { IconUpload } from 'components/Icons'
import { Button, CircularProgress } from '@mui/material'
import { RejectedFiles } from './RejectedFiles'
import { AcceptedFiles } from './AcceptedFiles'
import { api } from 'utils'
import { purchasesStore } from 'modules/Purchases/stores'

export const Modal = observer(() => {
	const { open, isFileLoading, isDisabledToSubmit, acceptedIds } = purchaseFormStore
	const { t: tMain } = useTranslation()
	const { t } = useTranslation('purchases')
	const [isDraggedOver, setIsDraggedOver] = React.useState(false)
	const [isSubmitting, setIsSubmitting] = React.useState(false)
	const inputRef = React.useRef<HTMLInputElement | null>(null)

	const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
		e.preventDefault()
		setIsDraggedOver(true)
	}

	const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
		e.preventDefault()
		setIsDraggedOver(false)
	}

	const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
		e.preventDefault()
		const allowedFormats = ['png', 'jpg', 'jpeg', 'pdf']
		const files = []
		for (const file of e.dataTransfer.files) {
			if(allowedFormats.includes(file.type.split('/')[1])) {
				files.push(file)
			}
		}
		handleFileLoad(files).catch(err => { console.log(err) })
		setIsDraggedOver(false)
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleFileLoad(e.currentTarget.files).catch(err => { console.log(err) })
	}

	const handleSubmit = async () => {
		setIsSubmitting(true)
		try {
			const res = await api.post('/purchases/submit', { purchases: acceptedIds })
			if(res.status === 200) {
				purchaseFormStore.clear()
				purchasesStore.savePurchasesToList(res.data.purchases)
			}
		} catch(err) {
			console.log(err)
		}
		setIsSubmitting(false)
	}

	const handleCancel = async () => {
		try {
			const res = await api.post('/purchases/cancel', { purchases: acceptedIds })
			if(res.status === 200) {
				purchaseFormStore.clear()
				purchaseFormStore.open = false
			}
		} catch(err) {
			console.log(err)
		}
	}

	React.useEffect(() => {
		function drop(this: Document, e: DragEvent) {
			e.preventDefault()
		}
		function dragover(this: Document, e: DragEvent) {
			e.preventDefault()
		}
		document.addEventListener('drop', drop)
		document.addEventListener('dragover', dragover)
		return () => {
			document.removeEventListener('drop', drop)
			document.removeEventListener('dragover', dragover)
		}
	}, [])

	React.useEffect(() => {
		if(!open && inputRef.current) {
			inputRef.current.value = ''
		}
	}, [open])

	return (
		<CustomModal open={open} onClose={closeFormModal} header={t('addPurchase')}>
			<label
				htmlFor='fileInput'
				className='h-[300px] w-full cursor-pointer rounded-[8px] border-[2px] border-dashed border-[var(--color-divider)] p-[32px] lg:w-[600px] lg:px-[62px] lg:py-[55px]'
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
			>
				<div className='flex h-full w-full flex-col items-center justify-center gap-[10px]'>
					<Show if={isDraggedOver}>
						<Heading4>{t('dropRightHere')}</Heading4>
					</Show>
					<Show if={!isDraggedOver}>
						<Show if={isFileLoading}>
							<CircularProgress />
						</Show>
						<Show if={!isFileLoading}>
							<div className='rounded-[6px] bg-[var(--opacity-color-gray-8)] p-[10px]'>
								<IconUpload />
							</div>
							<Heading4>{t('dropHereOrChooseFile')}</Heading4>
							<Paragraph>{t('allowedFormats')}{' '}PNG / JPG / PDF</Paragraph>
							<Paragraph>{t('maxAllowedSize')}{' '}10{tMain('mb')}</Paragraph>
						</Show>
					</Show>
				</div>
			</label>
			<RejectedFiles />
			<AcceptedFiles />
			<div className='flex items-center gap-[20px]'>
				<Button onClick={handleCancel} disabled={isSubmitting}><Paragraph>Cancel</Paragraph></Button>
				<Button sx={{ gap: '8px', padding: '10px 30px', paddingLeft: isSubmitting ? '9px' : '30px' }} variant='contained' onClick={handleSubmit} disabled={isDisabledToSubmit || isSubmitting}>
					<Show if={isSubmitting}>
						<CircularProgress size='17px' />
					</Show>
					<Paragraph color='inherit' style={{ opacity: (isSubmitting || isDisabledToSubmit) ? 0.5 : 1 }}>Submit</Paragraph>
				</Button>
			</div>
			<input multiple id='fileInput' type='file' className='hidden' accept='.png,.jpg,.jpeg,.pdf' ref={inputRef} onChange={handleInputChange} disabled={isFileLoading} />
		</CustomModal>
	)
})
