interface IContent {
	x: number
	y: number
	width: number
	height: number
	str: string
}

interface ICoordinates {
	top: number
	left: number
	width: number
	height: number
}

export const findMatch = (content: IContent[], searchObj: ICoordinates, resizeFactor: number): any => {
	searchObj = {
		top: (searchObj.top + searchObj.height / resizeFactor) / resizeFactor,
		left: Math.trunc(searchObj.left / resizeFactor),
		width: Math.round(searchObj.width / resizeFactor),
		height: searchObj.height / resizeFactor
	}
	let index = null as null | number
	let minimalDiffY = null as null | number
	let goodY = null as null | number
	let minimalDiffX = null as null | number
	// eslint-disable-next-line @typescript-eslint/no-for-in-array
	for (const _index in content) {
		const i = Number(_index)
		const diff = searchObj.top - content[i].y
		if((minimalDiffY === null && diff > -1) || (minimalDiffY !== null && diff > -1 && diff < minimalDiffY)) {
			minimalDiffY = diff
			goodY = content[i].y
		}
	}
	const neededRow = content.filter(item => goodY && Math.abs(item.y - goodY) <= 5)
	// eslint-disable-next-line @typescript-eslint/no-for-in-array
	for (const _index in neededRow) {
		const i = Number(_index)
		const diff = Math.abs(searchObj.left - neededRow[i].x)
		const isGoodDiff =
			neededRow[i].x >= searchObj.left &&
			neededRow[i].x + neededRow[i].width <= searchObj.left + searchObj.width
		if((minimalDiffX === null || (diff > 0 && diff < minimalDiffX)) && isGoodDiff) {
			minimalDiffX = diff
			index = i
		}
	}
	if (index !== null && neededRow[index].str) {
		if(neededRow[index].width < searchObj.width) {
			const additionalWords = neededRow.filter(row => {
				if(index !== null) {
					const maxLeftWithWidth = searchObj.left + searchObj.width
					return (row.x > neededRow[index].x) && ((row.x + row.width) < maxLeftWithWidth)
				} else {
					return false
				}
			})
			return {
				x: neededRow[index].x,
				y: neededRow[index].y,
				width: additionalWords.length ? additionalWords[additionalWords.length - 1].x + additionalWords[additionalWords.length - 1].width - neededRow[index].x : searchObj.width,
				height: neededRow[index].height,
				str: [neededRow[index].str, ...additionalWords.map(item => item.str)].join(' ')
			}
		}
		return neededRow[index]
	}
	return ''
}
