import Cookies from 'js-cookie'
import { type UserSession } from 'types'

export const getUser = (): UserSession | undefined => {
	const _user = Cookies.get('user')
	if(_user) {
		const user = JSON.parse(_user)
		return user
	} else {
		return undefined
	}
}
