import { editFormStore } from '../stores'

export const addTvaLine = () => {
	if(editFormStore?.form?.template) {
		const index = editFormStore.form.template.values.filter(value => value.name === 'tax').length
		editFormStore.form.template.values.push({
			index,
			name: 'tax'
		})
	}
	if(editFormStore?.form?.purchase) {
		editFormStore.form.purchase.tax.push({
			tax: '',
			tva: '',
			ht: ''
		})
	}
}
