import { type notifyProcessStatus } from 'types'
import { purchaseFormStore } from '../stores'
import { type IPurchase } from '../types'
import { runInAction } from 'mobx'

export const handlePurchasesSaveSuccess = (purchases: IPurchase[], status: notifyProcessStatus) => {
	const { acceptedFiles } = purchaseFormStore
	for(const purchase of purchases) {
		const index = acceptedFiles.findIndex(file => file.originalName === purchase.originalName)
		if(index > -1) {
			runInAction(() => { acceptedFiles[index].ready = true })
			if(purchase._id) runInAction(() => { acceptedFiles[index].id = String(purchase._id) })
		}
	}
	purchaseFormStore.isFileLoading = false
}
