import React from 'react'
import { observer } from 'mobx-react-lite'
import { TemplateValue } from './TemplateValue'
import { editFormStore } from 'modules/Purchases/stores'

interface ITemplateValuesMappedProps {
	isDrawn: boolean
}

export const TemplateValuesMapped: React.FC<ITemplateValuesMappedProps> = observer(({ isDrawn }) => {
	const { form, resizeFactor } = editFormStore
	if(form.template && isDrawn) {
		return <div className='pointer-events-none absolute left-0 top-0 z-10 h-full w-full transition-opacity hover:opacity-10'>
			{form.template.values
				.filter((value) => value?.coords?.page === form.currentPage)
				.map((value, i) => value.coords &&
					<TemplateValue
						key={value.name + '-' + i}
						name={value.name}
						coords={value.coords}
						index={value.index}
						resizeFactor={resizeFactor}
					/>
				)
			}
		</div>
	}
	return null
})
