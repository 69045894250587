import { Done } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material'
import { Label, ParagraphSemiBold, Show } from 'components'
import { observer } from 'mobx-react-lite'
import { purchaseFormStore } from 'modules/Purchases/stores'
import React from 'react'
import styled from 'styled-components'

const Shine = styled.div`
	background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0)));
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 );
	opacity: 0.5;
`

export const AcceptedFiles = observer(() => {
	const { acceptedFiles, isDisabledToSubmit } = purchaseFormStore

	const progressWidth = (acceptedFiles.filter(file => file.ready).length / acceptedFiles.length) * 100

	if(acceptedFiles.length) {
		return (<div className='w-full'>
			<Accordion sx={{ width: '100%' }} expanded={true}>
				<AccordionSummary sx={{ '& > .MuiAccordionSummary-content': { alignItems: 'center' } }}>
					<ParagraphSemiBold className='flex-1 text-left'>{acceptedFiles.length} ACCEPTED</ParagraphSemiBold>
					<Show if={progressWidth < 100}>
						<div className='relative h-[14px] flex-[2] overflow-hidden rounded-md bg-[var(--background-body)]'>
							<Shine className='absolute left-0 top-0 z-0 h-full w-full animate-shine' />
							<div className='relative z-[1] h-full bg-[var(--color-success)] transition-all' style={{ width: progressWidth + '%' }} />
						</div>
					</Show>
					<Show if={isDisabledToSubmit}>
					</Show>
					<Show if={progressWidth === 100}>
						<Show if={isDisabledToSubmit}>
							<CircularProgress color='inherit' size='24px' />
						</Show>
						<Show if={!isDisabledToSubmit}>
							<Done color='success' sx={{ marginLeft: 'auto' }} />
						</Show>
					</Show>
				</AccordionSummary>
				<AccordionDetails sx={{ textAlign: 'left', maxHeight: 'calc(100svh / 4)', overflowY: 'auto' }}>
					<div className='flex flex-col items-start gap-2'>
						{acceptedFiles.map((file, index) => <div key={`rejected-file-${index}`} className='inline-flex items-center justify-start gap-[8px]'>
							{file.ready ? <Done color='success' /> : <CircularProgress size='24px' />}
							<Label color={file.ready ? 'var(--color-success-400)' : undefined}>{file.originalName.length > 69 ? file.originalName.substring(0, 69) + ' ... .' + file.originalName.split('.').pop() : file.originalName}</Label>
						</div>)}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>)
	}
})
