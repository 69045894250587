import { Heading4, Show } from 'components'
import { observer } from 'mobx-react-lite'
import { purchasesStore } from 'modules/Purchases/stores'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const EmptyList = observer(() => {
	const { t } = useTranslation('purchases')
	const { purchases, isLoading } = purchasesStore

	return (
		<Show if={!purchases.length && !isLoading}>
			<div className='py-[5px] pt-[26px]'>
				<Heading4>{t('emptyList')}</Heading4>
			</div>
		</Show>
	)
})
