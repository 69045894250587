import type React from 'react'

interface ShowProps {
	if: boolean
	children: React.ReactNode
}

export const Show: React.FC<ShowProps> = ({ if: condition, children }) => {
	if(!condition) return null
	return children as React.ReactElement<any>
}
