import React from 'react'
import { motion, usePresence } from 'framer-motion'
import { Heading3 } from 'components/Typography'
import { useTranslation } from 'react-i18next'

const transition = { type: 'spring', stiffness: 500, damping: 50, mass: 1 }

export const GlobalLoader = () => {
	const { t } = useTranslation()
	const [isPresent, safeToRemove] = usePresence()

	return (
		<motion.div
			key='globalLoader'
			className='flex h-full w-full items-center justify-center bg-[var(--background-body)]'
			initial='out'
			animate={isPresent ? 'in' : 'out'}
			style={{
				position: isPresent ? 'static' : 'absolute'
			}}
			variants={{
				in: { opacity: 1 },
				out: { opacity: 0 }
			}}
			onAnimationComplete={() => { !isPresent && safeToRemove() }}
			// initial={{ opacity: 0 }}
			// animate={{ opacity: 1 }}
			// exit={{ opacity: 0 }}
		>
			<Heading3 className='animate-pulse'>{t('globalLoader')}</Heading3>
		</motion.div>
	)
}
