import styled from 'styled-components'
import { type RegisterFormProps } from './RegisterForm'

export const Root = styled.div<RegisterFormProps>`
	background-color: var(--background-card);
	padding: 32px;
	gap: 24px;
	display: flex;
	flex-direction: column;
	flex: 2;
	border-radius: ${({ variant }) => variant === 'card' ? '10px' : 'unset'};
	max-width: ${({ variant }) => variant === 'card' ? '550px' : 'unset'};
`

export const LogoContainer = styled.div<RegisterFormProps>`
	display: flex;
	align-items: center;
	justify-content: ${({ variant }) => variant === 'card' ? 'center' : 'flex-start'};
	padding: 10px 0;
	gap: 10px;
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 26px;
`

export const HeadingTextContainer = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 6px;
`

export const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export const Divider = styled.div`
	position: relative;
	width: 100%;
	height: 1px;
	background: var(--color-divider);
`

export const DividerText = styled.p`
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 0 24px;
	transform: translate(-50%, -50%);
	background: var(--background-card);
`

export const SocialButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`

export const HeadingText = styled.h2`
	color: var(--typography-heading);
	font-size: 26px;
	font-weight: 700;
	line-height: 36px;
`
