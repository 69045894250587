import React, { useContext } from 'react'
import { Root } from './styled'
import { IconBank, IconHome, IconLogo, IconPurchases } from 'components/Icons'
import { MenuSection } from './MenuSection'
import { MenuItem } from './MenuItem'
import { useTranslation } from 'react-i18next'
import { Show } from 'components/Show'
import { Settings } from '@mui/icons-material'
import { Title } from 'components/Typography'
import { ThemeContext } from 'theme'

interface IMenu {
	label?: string
	children: Array<{
		to?: string
		children?: Array<{
			to?: string
			label: string
			icon: React.ReactNode
		}>
		label: string
		icon: React.ReactNode
	}>
}

const MENUS: IMenu[] = [
	{
		children: [
			{ label: 'dashboard', to: '/', icon: <IconHome /> }
		]
	},
	{
		label: 'accounting',
		children: [
			{ label: 'purchases', to: '/purchases', icon: <IconPurchases /> },
			{ label: 'bank', children: [], icon: <IconBank /> }
		]
	},
	{
		children: [
			{ label: 'settings', to: '/settings', icon: <Settings /> }
		]
	}
]

export const Menu: React.FC = () => {
	const { t } = useTranslation()
	const { theme, isSafari } = useContext(ThemeContext)
	return (
		<Root $noPaddingTop={theme === 'dark' && isSafari}>
			<div className='flex items-center justify-center gap-[10px] lg2:justify-normal lg2:py-[20px] lg2:pl-[10px] lg2:pr-[14px]'>
				<IconLogo />
				<Title>{t('title')}</Title>
			</div>
			{MENUS.map((menu, index) => <MenuSection key={`menu-section-${index}`} title={menu?.label} className={(index + 1) === MENUS.length ? 'mt-auto' : undefined}>
				{menu.children.map((child, i) => <div key={`menu-section-${index}-child-${i}`}>
					<Show if={Boolean(child?.children?.length)}>
						<MenuItem key={`menu-section-${index}-${i}`} label={t(child.label)} icon={child.icon}>
							{JSON.stringify(child.children)}
						</MenuItem>
					</Show>
					<Show if={!child?.children?.length}>
						<MenuItem key={`menu-section-${index}-${i}`} label={t(child.label)} to={child.to} icon={child.icon} />
					</Show>
				</div>)}
			</MenuSection>)}
		</Root>
	)
}
