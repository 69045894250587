import React from 'react'
import { FormContainer } from './styled'
import { ErrorParagraph, InputText, Paragraph } from 'components'
import { InputCheckbox } from 'components/Inputs/InputCheckbox'
import { Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { registerStore } from 'modules/Register/stores'
import { changeError, changeField, register } from 'modules/Register/actions'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Form = observer(() => {
	const navigate = useNavigate()
	const { t } = useTranslation('auth')

	const { isSubmitting, form, error } = registerStore
	const { email, password, agreement, firstname, lastname } = form
	const { email: emailError, password: passwordError, firstname: firstnameError, lastname: lastnameError, agreement: agreementError } = error

	const isDisabledSubmit = Object.values(error).findIndex(item => item !== null) > -1

	const handleChange = ({ value, name }: { value: boolean | string, name: string }) => {
		changeField({ value, name })
		if((error as any)[name]) changeError({ name, value: null })
	}

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		await register(navigate).catch((err) => {
			console.log(err)
		})
	}

	return (
		<FormContainer onSubmit={handleSubmit}>
			<InputText name='firstname' autoComplete='given-name' type='text' label={t('firstname')} placeholder='Jon' value={firstname} onChange={handleChange} error={firstnameError} />
			<InputText name='lastname' autoComplete='family-name' type='text' label={t('lastname')} placeholder='Doe' value={lastname} onChange={handleChange} error={lastnameError} />
			<InputText name='email' autoComplete='username' type='email' label={t('email')} placeholder='jon.doe@gmail.com' value={email} onChange={handleChange} error={emailError} />
			<InputText name='password' autoComplete='new-password' type='password' label={t('password')} placeholder='●●●●●●●●' value={password} onChange={handleChange} error={passwordError} />
			<InputCheckbox
				className='self-start'
				name='agreement'
				label={<>{t('agreeTo')}{' '}<Link tabIndex={-1} to='/'>{t('privacyPolicyAndTerms')}</Link></>}
				value={agreement}
				onChange={handleChange}
			/>
			{agreementError && <ErrorParagraph>{t('agreementError')}</ErrorParagraph>}
			<Button disabled={isSubmitting || isDisabledSubmit} type='submit' variant='contained'>{t('signUp')}</Button>
			<Paragraph>{t('alreadyHaveAnAccount')}{' '}<Link to='/login'>{t('signInInstead')}</Link></Paragraph>
		</FormContainer>
	)
})
