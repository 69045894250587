import React, { useContext } from 'react'
import { Menu } from './Menu'
import { SearchBar } from './SearchBar'
import { Content, Page, Root } from './styled'
import { Outlet } from 'react-router-dom'
import { Footer } from './Footer'
import { ThemeContext } from 'theme'

export const PageWrapper: React.FC = () => {
	const { theme, isSafari } = useContext(ThemeContext)
	return (
		<Root>
			<Menu />
			<Content $noPaddingTop={theme === 'dark' && isSafari}>
				<SearchBar />
				<Page>
					<Outlet />
				</Page>
				<Footer />
			</Content>
		</Root>
	)
}
