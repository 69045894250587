import { api } from 'utils'
import { purchasesStore } from '../stores/purchasesStore'
import { appStore } from 'stores'

export const getPurchases = async (filter?: any) => {
	try {
		purchasesStore.isLoading = true
		const { meta } = purchasesStore
		if((meta.search ?? '') !== purchasesStore.lastSearch) appStore.isSearching = true
		const res = await api.get('/purchases', { params: { ...meta, filter } })
		if(res.status === 200) {
			purchasesStore.lastSearch = meta.search ?? ''
			purchasesStore.savePurchasesToList(res.data.purchases, appStore.isSearching, meta.page !== purchasesStore.lastPage)
			purchasesStore.meta = res.data.meta
			purchasesStore.isLoading = false
		}
		appStore.isSearching = false
	} catch(err) {
		console.log(err)
		purchasesStore.isLoading = false
		appStore.isSearching = false
		throw err
	}
}
