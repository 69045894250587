import { InputAreaSelection, SelectInput } from '..'
import { editFormStore } from 'modules/Purchases/stores/editFormStore'
import React, { useState } from 'react'
import { addTvaLine, handleTemplateValue } from 'modules/Purchases/actions'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { IconCalendarEvent, IconEuro } from 'components/Icons'
import { type COORD } from 'modules/Purchases/types'
import { InputText, ParagraphLeading } from 'components'
import { Button } from '@mui/material'
import { DUMMY_FORM_PURCHASE, PAYMENT_WAY_OPTIONS, TVA_OPTIONS } from 'modules/Purchases/consts'
import { submitEditForm } from 'modules/Purchases/actions/submitEditForm'
import { DeleteButton } from './DeleteButton'
import moment from 'moment'

export const Form: React.FC = observer(() => {
	const { t } = useTranslation('purchases')
	const { form, isSubmitting } = editFormStore
	const [errors, setErrors] = useState<any>({})

	const {
		title,
		totalTTC,
		totalHT,
		totalTVA,
		tax,
		date,
		paymentWay,
		invoiceNumber,
		originalName,
		documentEntity
	} = form.purchase ?? DUMMY_FORM_PURCHASE

	const handleChange = (x: { name: string, value: string, coords?: COORD, index: number }, cb?: () => void) => {
		if(errors[x.name]) {
			setErrors((prev: any) => ({
				...prev,
				[x.name]: undefined
			}))
		}
		handleTemplateValue(x, cb)
	}

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event): void => {
		event.preventDefault()
		let isError = false
		if(!paymentWay) {
			setErrors((prev: any) => ({
				...prev,
				paymentWay: 'true'
			}))
			isError = true
		}
		if(!documentEntity) {
			setErrors((prev: any) => ({
				...prev,
				documentEntity: 'fillPlease'
			}))
			isError = true
		}
		if(!isError) submitEditForm().catch(err => { console.log(err) })
	}

	return (
		<form autoComplete='off' className='flex h-full w-full flex-col gap-[16px]' onSubmit={handleSubmit}>
			<InputText label={t('filename')} name='originalName' value={originalName} autoComplete='off' disabled />
			<InputAreaSelection label={t('purchaseTitle')} name='title' value={title} onChange={handleChange} autoComplete='off' page={form.currentPage} />
			<InputAreaSelection error={errors?.documentEntity} label={t('documentEntity')} name='documentEntity' value={documentEntity} onChange={handleChange} autoComplete='off' page={form.currentPage} />
			<InputAreaSelection label={t('invoiceNumber')} name='invoiceNumber' value={invoiceNumber} onChange={handleChange} autoComplete='off' page={form.currentPage} />
			<InputAreaSelection label={t('date')} name='date' type='date' value={date ? moment(date).format('DD/MM/YYYY') : ''} onChange={handleChange} autoComplete='off' page={form.currentPage} endIcon={<IconCalendarEvent />} />
			<InputAreaSelection label={t('totalTTC')} name='totalTTC' value={totalTTC} onChange={handleChange} autoComplete='off' page={form.currentPage} endIcon={<IconEuro />} />
			<InputAreaSelection label={t('totalHT')} name='totalHT' value={totalHT} onChange={handleChange} autoComplete='off' page={form.currentPage} endIcon={<IconEuro />} />
			<InputAreaSelection label={t('totalTVA')} name='totalTVA' value={totalTVA} onChange={handleChange} autoComplete='off' page={form.currentPage} endIcon={<IconEuro />} />
			<SelectInput error={errors?.paymentWay} options={PAYMENT_WAY_OPTIONS} label={t('paymentWay')} name='paymentWay' value={paymentWay} onChange={handleChange} />
			<div className='mt-[32px] flex flex-col'>
				<div className='flex items-center justify-between'>
					<ParagraphLeading>{t('tva')}</ParagraphLeading>
					<Button onClick={addTvaLine}>{t('addTVALine')}</Button>
				</div>
				<div className='flex flex-col'>
					{tax.map((taxItem, i) =>
						<div key={`tax-${i}`} className='flex items-end gap-[8px] [&>div]:flex-1'>
							<InputAreaSelection label={t('HT')} name='tax.ht' value={taxItem.ht} onChange={handleChange} autoComplete='off' page={form.currentPage} endIcon={<IconEuro />} index={i} />
							<InputAreaSelection label={t('tva')} name='tax.tva' value={taxItem.tva} onChange={handleChange} autoComplete='off' page={form.currentPage} endIcon={<IconEuro />} index={i} />
							<SelectInput options={TVA_OPTIONS} label={t('tax')} name='tax.tax' value={taxItem.tax} onChange={handleChange} index={i} />
							{tax.length > 1 && <DeleteButton index={i} />}
						</div>
					)}
				</div>
			</div>
			<Button sx={{ marginTop: 'auto' }} type='submit' variant='contained' disabled={(title === originalName) || isSubmitting}>{t('submit')}</Button>
		</form>
	)
})
