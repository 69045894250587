import React from 'react'
import { Loader } from './Loader'
import { EmptyList } from './EmptyList'
import { List } from './List'

export const PurchasesList = () => {
	return (
		<div className='flex flex-1 flex-col gap-[16px] overflow-y-auto scroll-smooth pb-[16px]'>
			<List />
			<EmptyList key='emptyList' />
			<Loader key='loader' />
		</div>
	)
}
