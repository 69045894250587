import styled from 'styled-components'

interface SelectStateProps {
	$error?: boolean
	$valid?: boolean
	disabled?: boolean
	size?: 'small' | 'default' | 'large'
}

const getVar = (size: SelectStateProps['size'], value: string) => {
	switch (size) {
		case 'small':
			return `--form-input-9-placeholder-sm-${value}`
		case 'large':
			return `--form-input-7-placeholder-lg-${value}`
		default:
			return `--form-input-8-placeholder-default-${value}`
	}
}

const getPadding = (size: SelectStateProps['size']) => {
	switch (size) {
		case 'small':
			return '7px 10px'
		case 'large':
			return '13px 16px'
		default:
			return '7px 14px'
	}
}

const getBorderRadius = (size: SelectStateProps['size']) => {
	switch (size) {
		case 'small':
			return '4px'
		case 'large':
			return '8px'
		default:
			return '6px'
	}
}

export const Select = styled.select<SelectStateProps>`
	width: 100%;
	height: 40px;
	padding: ${({ size }) => getPadding(size)};
	border-radius: ${({ size }) => getBorderRadius(size)};
	border: 1px solid var(${({ $error, $valid }) => $error ? '--color-danger' : $valid ? '--color-success' : '--color-divider'});
	background: var(${({ disabled }) => disabled ? '--opacity-color-gray-8' : '--background-card'});
	color: var(${({ $error }) => $error ? '--color-danger' : '--typography-heading'});
	animation: ${({ $error }) => $error ? '0.25s horizontal-shaking 2' : 'unset'};
	font-family: var(${({ size }) => getVar(size, 'font-family')});
	font-size: var(${({ size }) => getVar(size, 'font-size')});
	font-style: var(${({ size }) => getVar(size, 'font-style')});
	font-weight: var(${({ size }) => getVar(size, 'font-weight')});
	letter-spacing: var(${({ size }) => getVar(size, 'letter-spacing')});
	line-height: var(${({ size }) => getVar(size, 'line-height')});appearance: none;
	&::placeholder {
		color: var(--typography-placeholder);
	}
`
