import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconEuro: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
			<path d="M17.5636 7C15.2081 4.30738 11.4314 4.33735 9.10745 7.06712C6.78352 9.79689 6.78352 14.2031 9.10745 16.9329C11.4314 19.6627 15.2081 19.6926 17.5636 17" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M17.5636 7C15.2081 4.30738 11.4314 4.33735 9.10745 7.06712C6.78352 9.79689 6.78352 14.2031 9.10745 16.9329C11.4314 19.6627 15.2081 19.6926 17.5636 17" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M13.3635 10.75C13.7777 10.75 14.1135 10.4142 14.1135 10C14.1135 9.58579 13.7777 9.25 13.3635 9.25V10.75ZM5.36353 9.25C4.94931 9.25 4.61353 9.58579 4.61353 10C4.61353 10.4142 4.94931 10.75 5.36353 10.75V9.25ZM5.36353 13.25C4.94931 13.25 4.61353 13.5858 4.61353 14C4.61353 14.4142 4.94931 14.75 5.36353 14.75V13.25ZM13.3635 14.75C13.7777 14.75 14.1135 14.4142 14.1135 14C14.1135 13.5858 13.7777 13.25 13.3635 13.25V14.75ZM13.3635 9.25H5.36353V10.75H13.3635V9.25ZM5.36353 14.75H13.3635V13.25H5.36353V14.75Z" fill={stroke}/>
			<path d="M13.3635 10.75C13.7777 10.75 14.1135 10.4142 14.1135 10C14.1135 9.58579 13.7777 9.25 13.3635 9.25V10.75ZM5.36353 9.25C4.94931 9.25 4.61353 9.58579 4.61353 10C4.61353 10.4142 4.94931 10.75 5.36353 10.75V9.25ZM5.36353 13.25C4.94931 13.25 4.61353 13.5858 4.61353 14C4.61353 14.4142 4.94931 14.75 5.36353 14.75V13.25ZM13.3635 14.75C13.7777 14.75 14.1135 14.4142 14.1135 14C14.1135 13.5858 13.7777 13.25 13.3635 13.25V14.75ZM13.3635 9.25H5.36353V10.75H13.3635V9.25ZM5.36353 14.75H13.3635V13.25H5.36353V14.75Z" fill="white" fillOpacity="0.2"/>
		</svg>
	)
}
