import React from 'react'
import { Modal as _Modal } from 'components'
import { observer } from 'mobx-react-lite'
import { editFormStore } from 'modules/Purchases/stores'
import { clearEditFormStore } from 'modules/Purchases/actions'
import { getPurchase } from 'modules/Purchases/actions/getPurchase'
import { useTranslation } from 'react-i18next'

export const Modal: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
	const { t } = useTranslation('purchases')
	const { open, form } = editFormStore

	const onClose = () => {
		clearEditFormStore()
	}
	React.useEffect(() => {
		if(open) {
			(async () => { await getPurchase(form.purchaseId) })().catch(err => { console.log(err) })
		}
	}, [open])
	return (
		<_Modal open={Boolean(open && form.purchase)} onClose={onClose} header={t('verifyData')} $padding='12px 24px'>
			{children}
		</_Modal>
	)
})
