import { socket } from 'utils'
import { getPurchases } from './getPurchases'
import { handlePurchaseProcessSuccess, handlePurchasesSaveSuccess } from '.'
import { getPurchasesDates } from './getPurchasesDates'
import { type IPurchase } from '../types'
import { type notifyProcessStatus } from 'types'

export const mount = () => {
	socket.connect()
	socket.on('res:save', ({ purchases, status }: { purchases: IPurchase[], status: notifyProcessStatus }) => {
		handlePurchasesSaveSuccess(purchases, status)
	})
	socket.on('res:process', ({ purchase, status }: { purchase: IPurchase, status: notifyProcessStatus }) => {
		handlePurchaseProcessSuccess(purchase, status)
	})
	getPurchases().catch(err => { console.log(err) })
	getPurchasesDates().catch(err => { console.log(err) })
}
