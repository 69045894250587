import { type CSSProperties } from 'react'
import { styled } from 'styled-components'

export const Root = styled.div<{ $open?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: var(--opacity-color-gray-16); */
  backdrop-filter: blur(1.25px);
  -webkit-backdrop-filter: blur(1.25px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  pointer-events: ${({ $open }) => ($open ? 'all' : 'none')};
  transition: 0.1s all ease-out;
  padding: 64px;
  @media screen and ( max-width: 1024px ) {
	padding: 0;
	min-width: 100svw;
	min-height: 100svh;
	width: 100%;
	height: 100%;
  }
`

export const ModalCard = styled.div<{ $padding?: CSSProperties['padding'] }>`
  border-radius: 10px;
  background: var(--background-card);
  box-shadow: var(--shadow-modal);
  padding: ${({ $padding }) => $padding ?? '65px'};
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  max-height: 100%;
  @media screen and ( max-width: 1024px ) {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 16px 16px 64px;
	border-radius: 0;
	justify-content: center;
	background: var(--background-body);
  }
`

export const ModalHeader = styled.div`
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  @media screen and ( max-width: 1024px ) {
	margin: 0 auto auto;
	max-width: unset;
  }
`

export const ModalBody = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow-y: auto;
`

export const ModalButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ModalClose = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  display: flex;
  padding: 6px;
  justify-content: flex-end;
  align-items: flex-start;
  box-shadow: var(--shadow-gray-SM);
  background: var(--background-card);
  border-radius: 6px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`
