import { Heading3, Paragraph } from 'components'
import React from 'react'

export const Support = () => {
	return (
		<div className='flex flex-col'>
			<Heading3>SUPPORT</Heading3>
			<Paragraph>THIS PAGE IS IN DEVELOPMENT AT THE MOMENT</Paragraph>
		</div>
	)
}
