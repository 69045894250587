import { purchasesStore } from '../stores'
import { getPurchases } from './getPurchases'

export const handleGetMorePurchases = async (filter?: any) => {
	purchasesStore.meta = {
		...purchasesStore.meta,
		page: purchasesStore.meta.page + 1
	}
	await getPurchases(filter).catch(err => { throw err })
}
