import React from 'react'
import { Show, Extractor as _Extractor } from 'components'
import { observer } from 'mobx-react-lite'
import { editFormStore } from 'modules/Purchases/stores'
import { useAreaSelection } from 'hooks'
import { CircularProgress } from '@mui/material'

export const Extractor = observer(() => {
	const { isSelectionActive, canvasRef } = useAreaSelection()
	const { open, resizeFactor } = editFormStore
	const { purchase, currentPage } = editFormStore.form
	const [file, setFile] = React.useState<File | undefined>(undefined)
	const [isDrawn, setIsDrawn] = React.useState(false)

	React.useEffect(() => {
		(async () => {
			setIsDrawn(false)
			if(purchase?.original) {
				const res = await fetch(process.env.REACT_APP_BACK_URL + '/static' + purchase?.original, { credentials: 'include' })
				if(res.status === 200) {
					const _blob = await res.blob()
					if(_blob) {
						const blob = new Blob([_blob], { type: 'application/pdf' })
						const file = new File([blob], purchase?.original ?? '', { type: 'application/pdf' })
						setFile(file)
					} else {
						setIsDrawn(true)
					}
				} else {
					setIsDrawn(true)
				}
			} else {
				setFile(undefined)
				setIsDrawn(true)
			}
		})().catch(err => { console.log(err) })
	}, [resizeFactor, purchase?.original])

	React.useEffect(() => {
		if(!open) {
			setIsDrawn(false)
			const canvas = canvasRef.current
			if(canvas) {
				const ctx = canvas.getContext('2d')
				if(ctx) {
					ctx.clearRect(0, 0, canvas.width, canvas.height)
				}
			}
		}
	}, [open])

	const onPageLoadSuccess = () => {
		setIsDrawn(true)
	}

	return <div className={`relative ${isSelectionActive ? 'hover:cursor-crosshair' : ''}`}>
		<Show if={!isDrawn}>
			<div className='absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center bg-[var(--background-body)]'>
				<CircularProgress />
			</div>
		</Show>
		<_Extractor
			onPageLoadSuccess={onPageLoadSuccess}
			purchase={purchase}
			isDrawn={isDrawn}
			currentPage={currentPage}
			file={file}
			resizeFactor={resizeFactor}
			renderTextLayer={!isSelectionActive}
		/>
	</div>
})
