import React from 'react'
import { FormContainer } from './styled'
import { InputText, Paragraph } from 'components'
import { InputCheckbox } from 'components/Inputs/InputCheckbox'
import { Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { loginStore } from 'modules/Login/stores'
import { changeError, changeField, login } from 'modules/Login/actions'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Form = observer(() => {
	const navigate = useNavigate()
	const { t } = useTranslation('auth')
	const { isSubmitting, form, error } = loginStore
	const { email, password, rememberMe } = form
	const { email: emailError, password: passwordError } = error

	const isDisabledSubmit = Object.values(error).findIndex(item => item !== null) > -1

	const handleChange = ({ value, name }: { value: boolean | string, name: string }) => {
		changeField({ value, name })
		if((error as any)[name]) changeError({ name, value: null })
	}

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		await login(navigate).catch((err) => {
			console.log(err)
		})
	}
	return (
		<FormContainer onSubmit={handleSubmit}>
			<InputText name='email' autoComplete='username' type='email' label={t('email')} placeholder='jon.doe@gmail.com' value={email} onChange={handleChange} error={emailError} />
			<InputText name='password' autoComplete='current-password' type='password' label={t('password')} placeholder='●●●●●●●●' value={password} onChange={handleChange} error={passwordError} />
			<InputCheckbox
				className='self-start'
				name='rememberMe'
				label={t('rememberMe')}
				value={rememberMe}
				onChange={handleChange}
			/>
			<Button disabled={isSubmitting || isDisabledSubmit} type='submit' variant='contained'>{t('signIn')}</Button>
			<Paragraph>{t('dontHaveAnAccount')}{' '}<Link to='/register'>{t('createAccount')}</Link></Paragraph>
		</FormContainer>
	)
})
