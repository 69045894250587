import styled from 'styled-components'

interface InputStateProps {
	$error?: boolean
	$valid?: boolean
	disabled?: boolean
	size?: 'small' | 'default' | 'large'
	'data-form-type'?: string
}

const getVar = (size: InputStateProps['size'], value: string) => {
	switch(size) {
		case 'small':
			return `--form-input-9-placeholder-sm-${value}`
		case 'large':
			return `--form-input-7-placeholder-lg-${value}`
		default:
			return `--form-input-8-placeholder-default-${value}`
	}
}

const getPadding = (size: InputStateProps['size']) => {
	switch(size) {
		case 'small':
			return '7px 10px'
		case 'large':
			return '13px 16px'
		default:
			return '7px 14px'
	}
}

const getBorderRadius = (size: InputStateProps['size']) => {
	switch(size) {
		case 'small':
			return '4px'
		case 'large':
			return '8px'
		default:
			return '6px'
	}
}

export const Root = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: flex-start;
`

export const Placeholder = styled.span<{ $visible?: boolean, size?: 'small' | 'default' | 'large' }>`
	position: absolute;
	top: 50%;
	left: 2px;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	gap: 4px;
	pointer-events: none;
	opacity: ${({ $visible }) => !$visible ? '0' : '1'};
	padding: ${({ size }) => getPadding(size)};
	font-family: var(${({ size }) => getVar(size, 'font-family')});
	font-size: var(${({ size }) => getVar(size, 'font-size')});
	font-style: var(${({ size }) => getVar(size, 'font-style')});
	font-weight: var(${({ size }) => getVar(size, 'font-weight')});
	letter-spacing: var(${({ size }) => getVar(size, 'letter-spacing')});
	line-height: var(${({ size }) => getVar(size, 'line-height')});
	color: var(--typography-placeholder);
`

export const Input = styled.input<InputStateProps>`
	width: 100%;
	padding: ${({ size }) => getPadding(size)};
	border-radius: ${({ size }) => getBorderRadius(size)};
	border: 1px solid var(${({ $error, $valid }) => $error ? '--color-danger' : $valid ? '--color-success' : '--color-divider'});
	background: var(${({ disabled }) => disabled ? '--opacity-color-gray-8' : '--background-card'});
	color: var(${({ $error }) => $error ? '--color-danger' : '--typography-heading'});
	animation: ${({ $error }) => $error ? '0.25s horizontal-shaking 2' : 'unset'};
	font-family: var(${({ size }) => getVar(size, 'font-family')});
	font-size: var(${({ size }) => getVar(size, 'font-size')});
	font-style: var(${({ size }) => getVar(size, 'font-style')});
	font-weight: var(${({ size }) => getVar(size, 'font-weight')});
	letter-spacing: var(${({ size }) => getVar(size, 'letter-spacing')});
	line-height: var(${({ size }) => getVar(size, 'line-height')});
	&:focus {
		${Placeholder} {
			opacity: 0;
		}
	}
`

export const InputMessage = styled.p<InputStateProps>`
	color: var(${({ $error, $valid }) => $error ? '--color-danger' : $valid ? '--color-success' : '--typography-muted'});
	font-family: var(--form-input-3-label-sm-font-family);
	font-size: var(--form-input-3-label-sm-font-size);
	font-style: var(--form-input-3-label-sm-font-style);
	font-weight: var(--form-input-3-label-sm-font-weight);
	letter-spacing: var(--form-input-3-label-sm-letter-spacing);
	line-height: var(--form-input-3-label-sm-line-height);
`

export const IconContainer = styled.label<{ $right?: string }>`
	position: absolute;
	top: 50%;
	right: ${({ $right }) => $right ?? '16px'};
	transform: translateY(-50%);
`
