import React from 'react'
import { Root } from './styled'
import { InputText, type InputTextProps } from 'components'
import { uniqueId } from 'lodash'
import { IconAreaSelection } from 'components/Icons'
import { IconButton } from '@mui/material'
import { useAreaSelection } from 'hooks'
import { type COORD } from 'modules/Purchases/types'

interface InputAreaSelectionProps extends Omit<InputTextProps, 'onChange' | 'type'> {
	onChange: ({ value, name, coords, index }: { value: string, name: string, coords?: COORD, index: number }, cb?: () => void) => void
	type?: 'text' | 'date'
	page: number
	index?: number
}

export const InputAreaSelection: React.FC<InputAreaSelectionProps> = ({
	id: _id,
	name,
	value,
	label,
	type = 'text',
	page,
	endIcon,
	index,
	error,
	onChange
}) => {
	const [id] = React.useState(_id ?? uniqueId('input-area-'))
	const handleAreaChange = (coords: COORD, value: string, name: string, index: number, cb: () => void) => {
		onChange({ name, value, coords, index }, cb)
	}
	const {
		activateSelection,
		isSelectionActive,
		name: selectionName,
		currenTemplateValueFocused,
		setCurrentTemplateValueFocused
	} = useAreaSelection({ onChange: handleAreaChange, page })

	const handleChange = ({ name, value }: { name: string, value: string }) => {
		if(!isSelectionActive) onChange({ name, value, index: index ?? 0 })
	}

	const onFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
		setCurrentTemplateValueFocused(`${event.currentTarget.name}:${index ?? 0}`)
	}

	const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
		if(currenTemplateValueFocused.split(':')[0] === event.currentTarget.name) setCurrentTemplateValueFocused('')
	}

	return (
		<Root>
			<InputText
				id={id}
				name={name}
				value={value}
				label={label}
				onChange={handleChange}
				disabled={isSelectionActive && selectionName === name}
				endIcon={endIcon}
				topRight={<IconButton size='small' sx={{ marginRight: '-5px' }} onClick={() => { activateSelection(name, index) }}><IconAreaSelection /></IconButton>}
				error={error}
				onFocus={onFocus}
				onBlur={onBlur}
				dataFormType='other'
			/>
		</Root>
	)
}
