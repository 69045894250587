import { api } from 'utils'
import { editFormStore, purchasesStore } from '../stores'
import { mapPurchaseToUpdate, mapTemplateBeforeSubmit } from '../utils'

export const submitEditForm = async () => {
	editFormStore.isSubmitting = true
	try {
		const { purchase: _purchase, template: _template, purchaseId } = editFormStore.form
		const template = mapTemplateBeforeSubmit(_template)
		const purchase = mapPurchaseToUpdate(_purchase)
		const res = await api.put(`/purchases/${purchaseId}`, {
			purchase,
			template
		})
		if(res.status === 200 && purchase) {
			editFormStore.open = false
			purchasesStore.updatePurchase(purchaseId, { ...purchase, processed: true })
		}
	} catch(err) {
		console.log(err)
	}
	editFormStore.isSubmitting = false
}
