import React from 'react'
import { type IconProps } from '.'

export const IconFacebook: React.FC<IconProps> = () => {
	return (
		<svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
			<rect width="38" height="38" rx="6" fill="#4267B2" fillOpacity="0.16"/>
			<path d="M22.7159 16.1809H20.1839V14.5203C20.1839 13.8966 20.5973 13.7512 20.8884 13.7512C21.1788 13.7512 22.6752 13.7512 22.6752 13.7512V11.0096L20.2144 11C17.4827 11 16.861 13.0448 16.861 14.3533V16.1809H15.2812V19.006H16.861C16.861 22.6315 16.861 27 16.861 27H20.1839C20.1839 27 20.1839 22.5885 20.1839 19.006H22.4261L22.7159 16.1809Z" fill="#4267B2"/>
		</svg>
	)
}
