import React, { type CSSProperties, useEffect, useState } from 'react'
import { Root, ModalCard, ModalBody, ModalHeader, ModalClose } from './styled'
import { Heading3 } from 'components/Typography'
import { IconClose } from 'components/Icons'
import { IconButton, useMediaQuery } from '@mui/material'
import { Show } from 'components/Show'
import { ArrowBack } from '@mui/icons-material'

interface ModalProps {
	open?: boolean
	header?: string
	headerColor?: string
	$padding?: CSSProperties['padding']
	onClose?: () => void
	children: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({
	open: _open,
	header,
	headerColor,
	$padding,
	children,
	onClose
}) => {
	const [open, setOpen] = useState(_open ?? false)
	const isMobile = useMediaQuery('( max-width: 1024px )')

	const handleClose = () => {
		setOpen(false)
		if (onClose) onClose()
	}
	const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation()
	}

	useEffect(() => {
		setOpen(_open ?? false)
	}, [_open])

	return (
		<Root onClick={handleClose} $open={open}>
			<ModalCard onClick={stopPropagation} $padding={$padding}>
				<ModalHeader>
					<Show if={isMobile}>
						<IconButton onClick={handleClose}>
							<ArrowBack />
						</IconButton>
					</Show>
					<Show if={Boolean(header)}>
						<Heading3 className='mr-[40px] w-full' style={{ color: headerColor, marginLeft: !isMobile ? '40px' : '0' }}>{header}</Heading3>
					</Show>
				</ModalHeader>
				<ModalBody>
					{children}
				</ModalBody>
				<Show if={!isMobile}>
					<ModalClose onClick={handleClose}>
						<IconClose />
					</ModalClose>
				</Show>
			</ModalCard>
		</Root>
	)
}
