import styled from 'styled-components'

export const Root = styled.div`
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	overflow: hidden;
	cursor: pointer;
`

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	background: var(--color-primary-100);
`
