import styled from 'styled-components'
import { IconWrapper } from '../styled'

export const ProcessedIconWrapper = styled(IconWrapper)`
	width: 38px;
	height: 38px;
	background: var(--opacity-color-success-16);
	svg {
		path {
			stroke: var(--color-success-600);
		}
	}
`

export const ProcessingIconWrapper = styled(IconWrapper)`
	width: 38px;
	height: 38px;
	background: var(--opacity-color-info-16);
	svg {
		circle {
			stroke: var(--color-info-600)
		}
	}
`

export { IconWrapper }
