import React, { useEffect, useState } from 'react'
import _Calendar, { type OnClickFunc, type TileClassNameFunc } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { type OnArgs, type View } from 'react-calendar/dist/cjs/shared/types'

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

interface ICalendarProps {
	value: Value
	tileClassName?: TileClassNameFunc | string
	view?: View
	onClickDay?: OnClickFunc
	onClickMonth?: OnClickFunc
}

export const Calendar: React.FC<ICalendarProps> = ({
	value,
	tileClassName,
	view: _view,
	onClickDay,
	onClickMonth
}) => {
	const [view, setView] = useState<View>(_view ?? 'month')

	const handleViewChange = ({ view }: OnArgs) => {
		setView(view)
	}

	useEffect(() => {
		if(_view) setView(_view)
	}, [_view])

	return <_Calendar
		view={view}
		value={value}
		tileClassName={tileClassName}
		onClickDay={onClickDay}
		onClickMonth={onClickMonth}
		onViewChange={handleViewChange}
	/>
}
