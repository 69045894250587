import { Button, CircularProgress } from '@mui/material'
import { InputText, Modal, Paragraph, ParagraphLeading, Show } from 'components'
import { observer } from 'mobx-react-lite'
import { closeDeleteModal, setDeleteModal } from 'modules/Purchases/actions'
import { handleDeletePurchase } from 'modules/Purchases/actions/handleDeletePurchase'
import { purchasesStore } from 'modules/Purchases/stores'
import React, { type FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const DeleteModal = observer(() => {
	const { t } = useTranslation()
	const { isDeleting, deleteModal, getPurchaseFromList } = purchasesStore
	const [inputValue, setInputValue] = useState('')
	const purchase = getPurchaseFromList(typeof deleteModal === 'string' ? deleteModal : '')

	const title = purchase?.title ? purchase.title : purchase?.originalName

	const handleChangeInputValue = ({ value }: { value: string }) => {
		setInputValue(value)
	}

	const handleCancel = () => {
		setDeleteModal(false)
		setInputValue('')
	}

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		handleDeletePurchase().catch(err => { console.log(err) })
		setInputValue('')
	}

	return (
		<Modal open={Boolean(deleteModal)} onClose={closeDeleteModal} header={t('delete')} headerColor='var(--color-danger)'>
			<form className='flex flex-col items-center gap-[64px] lg:min-w-[700px]' onSubmit={handleSubmit}>
				<Show if={isDeleting}>
					<CircularProgress />
				</Show>
				<Show if={!isDeleting}>
					<ParagraphLeading className='select-none'>{t('areYouSureYouWantToDelete')} {title}{t('questionMark')}</ParagraphLeading>
					<Show if={Boolean(purchase?.title)}>
						<InputText
							name='deleteInput'
							label={`${t('type')} "${purchase?.title}" ${t('toDeletePurchase')}`}
							value={inputValue}
							onChange={handleChangeInputValue}
							deleteStyle
							autoFocus
						/>
					</Show>
					<div className='flex items-center gap-[32px]'>
						<Button variant='contained' onClick={handleCancel} disabled={isDeleting}>{t('cancel')}</Button>
						<Button color='error' type='submit' disabled={isDeleting || inputValue !== purchase?.title}>{t('delete')}</Button>
					</div>
				</Show>
			</form>
		</Modal>
	)
})
