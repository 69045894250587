import { type View } from 'react-calendar/dist/cjs/shared/types'
import { purchasesStore } from '../stores'

export const toggleCalendarModal = (value?: { day?: number | string, month: number | string, year: number | string }) => {
	let date: undefined | Date
	let view: View = 'month'
	if(value) {
		const day = value?.day ? Number(value.day) : undefined
		if(!day) view = 'year'
		const month = Number(value.month)
		const year = Number(value.year)
		date = new Date()
		date.setDate(day ?? 1)
		date.setMonth(month)
		date.setFullYear(year)
	}
	purchasesStore.calendarModal = date ? `${view}|${date.toISOString()}` : !purchasesStore.calendarModal
}
