import { languages } from 'consts'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import US_FLAG from 'assets/img/us.png'
import FR_FLAG from 'assets/img/fr.png'
import styled from 'styled-components'
import { SettingItem, Settings, SettingsClose } from './styled'
import { IconButton } from '@mui/material'

const FLAGS = {
	en: US_FLAG,
	fr: FR_FLAG
}

const ImageWrapper = styled.div`
	width: 26px;
	height: 26px;
	border-radius: 100%;
	overflow: hidden;
	cursor: pointer;
`

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export const LanguageSwitch = () => {
	const { i18n: { language, changeLanguage } } = useTranslation()
	const [show, setShow] = useState(false)

	const handleToggleShow = () => {
		setShow(prev => !prev)
	}

	const handleSelectLanguage = async (e: React.MouseEvent<HTMLButtonElement>) => {
		await changeLanguage(e.currentTarget.id)
		setShow(false)
	}

	const handleOffShow = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		setShow(false)
	}

	return (
		<div className='relative flex h-[38px] w-[38px] items-center justify-center'>
			<IconButton onClick={handleToggleShow}>
				<ImageWrapper>
					<Image src={(FLAGS as any)[language]} alt={language} />
				</ImageWrapper>
			</IconButton>
			<SettingsClose $show={show} onClick={handleOffShow} />
			<Settings onClick={e => { e.stopPropagation() }} $show={show}>
				{languages.map(item => <SettingItem key={item} id={item} onClick={handleSelectLanguage} >
					<ImageWrapper>
						<Image src={(FLAGS as any)[item]} alt={item} />
					</ImageWrapper>
				</SettingItem>)}
			</Settings>
		</div>
	)
}
