import { api } from 'utils'
import { editFormStore } from '../stores'
import { mapPurchaseFromApi, mapTemplateFromPurchase } from '../utils'
import { runInAction } from 'mobx'

export const getPurchase = async (purchaseId: string) => {
	try {
		const res = await api.get(`/purchases/${purchaseId}`)
		if(res.status === 200) {
			runInAction(() => {
				editFormStore.form.purchase = mapPurchaseFromApi(res.data.purchase)
				editFormStore.form.template = mapTemplateFromPurchase(res.data.purchase)
			})
		}
	} catch(err) {
		console.log(err)
	}
}
