import styled from 'styled-components'

export const Root = styled.div`
	display: flex;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DocumentContent = styled.div`
	position: relative;
	background: var(--background-body);
	border-radius: 6px;
	overflow: hidden;
	min-width: 595.266px;
	min-height: 841.875px;
	display: flex;
	align-items: center;
	justify-content: center;
	> img {
		pointer-events: none;
	}
	@media screen and ( min-height: 1280px ) {
		min-width: 714.328px;
		min-height: 1010.270px;
	}
	@media screen and ( max-width: 1280px ) {
		min-width: 535.734px;
		min-height: 757.688px;
	}
	@media screen and ( max-width: 768px ) {
		min-width: 416.688px;
		min-height: 589px;
	}
`
