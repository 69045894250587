import React from 'react'
import { Content, DocumentContent, Root } from './styled'
import { useAreaSelection } from 'hooks'
import { TemplateValuesMapped } from 'modules/Purchases/components/TemplateValuesMapped/TemplateValuesMapped'
import { Document, Page, pdfjs } from 'react-pdf'
import { type IPurchase } from 'modules/Purchases/types'
import 'react-pdf/dist/Page/TextLayer.css'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import { Paragraph } from 'components/Typography'
import { type TFunction } from 'i18next'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

interface ExtractorProps {
	currentPage: number
	resizeFactor: number
	purchase: IPurchase | null
	renderTextLayer?: boolean
	file: File | undefined
	isDrawn: boolean
	onPageLoadSuccess: () => void
}

export const Extractor: React.FC<ExtractorProps> = ({ isDrawn, file, purchase, currentPage, resizeFactor, renderTextLayer, onPageLoadSuccess }) => {
	const { containerProps, Selection, canvasRef } = useAreaSelection({ purchase, resizeFactor })
	const { t } = useTranslation()

	return (
		<Root>
			<Content>
				<DocumentContent {...containerProps}>
					<Document
						className="pdfDocument"
						file={file}
						onError={err => { console.log(err) }}
						error={<Error t={t}/>}
						loading={<Loading t={t}/>}
						noData={<NoData t={t}/>}
					>
						<Page
							scale={resizeFactor}
							canvasRef={(ref) => {
								canvasRef.current = ref
							}}
							className="pdfPage"
							renderAnnotationLayer={false}
							pageNumber={currentPage}
							onRenderSuccess={onPageLoadSuccess}
							renderTextLayer={renderTextLayer}
						/>
					</Document>
					<Selection />
					<TemplateValuesMapped isDrawn={isDrawn} />
				</DocumentContent>
			</Content>
		</Root>
	)
}

const Error = ({ t }: { t: TFunction }) => (
	<div className='w-full'>
		<Paragraph>{t('errorPdfFile')}</Paragraph>
	</div>
)

const Loading = ({ t }: { t: TFunction }) => (
	<div>
		<CircularProgress />
	</div>
)

const NoData = ({ t }: { t: TFunction }) => (
	<div className='w-full'>
		<Paragraph>{t('noPdfFileData')}</Paragraph>
	</div>
)
