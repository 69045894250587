import React, { type ChangeEvent, useState, useEffect, type CSSProperties } from 'react'
import uniqueId from 'lodash/uniqueId'
import { Label } from 'components/Typography'
import { Input, InputMessage, IconContainer, Root, Placeholder } from './styled'
import { IconEye, IconEyeOff } from 'components/Icons'
import { useDebounce } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import { Show } from 'components'

export interface InputTextProps {
	name: string
	value: string
	type?: 'email' | 'text' | 'password'
	label?: string
	className?: string
	placeholder?: string | React.ReactNode
	message?: string
	error?: null | string
	autoFocus?: boolean
	deleteStyle?: boolean
	valid?: null | string
	disabled?: boolean
	autoComplete?: 'name' | 'honorific-prefix' | 'given-name' | 'additional-name' | 'family-name' | 'honorific-suffix' | 'nickname' | 'username' | 'new-password' | 'current-password' | 'one-time-code' | 'organization-title' | 'organization' | 'street-address' | 'address-line1' | 'address-line2' | 'address-line3' | 'address-level4' | 'address-level3' | 'address-level2' | 'address-level1' | 'country' | 'country-name' | 'postal-code' | 'cc-name' | 'cc-given-name' | 'cc-additional-name' | 'cc-family-name' | 'cc-number' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'transaction-currency' | 'transaction-amount' | 'language' | 'bday' | 'bday-day' | 'bday-month' | 'bday-year' | 'sex' | 'url' | 'photo' | 'off'
	id?: string
	debounce?: number
	size?: 'small' | 'default' | 'large'
	dataFormType?: string
	endIcon?: React.ReactNode
	topRight?: React.ReactNode
	tabIndex?: number
	inputRef?: React.MutableRefObject<null | HTMLInputElement>
	onFocus?: React.FocusEventHandler<HTMLInputElement>
	onBlur?: React.FocusEventHandler<HTMLInputElement>
	onChange?: ({ value, name }: { value: string, name: string }) => void
}

export const InputText: React.FC<InputTextProps> = ({
	id: _id,
	type: _type = 'text',
	size = 'default',
	debounce = 0,
	value: _value = '',
	className,
	label,
	placeholder,
	message,
	error,
	autoFocus,
	valid,
	disabled,
	name,
	autoComplete,
	endIcon,
	topRight,
	tabIndex,
	inputRef,
	dataFormType,
	deleteStyle,
	onFocus,
	onBlur,
	onChange
}) => {
	const { t } = useTranslation()

	const style: CSSProperties = deleteStyle ? { color: 'var(--color-danger)', userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', WebkitUserSelect: 'none', textTransform: 'unset' } : {}
	const [id] = useState(_id ?? uniqueId(`input-${_type}-`))
	const [type, setType] = useState(_type)
	const [value, setValue] = useState(_value)
	const [debouncedValue] = useDebounce(value, debounce)

	const togglePasswordVisibility = () => {
		if(_type === 'password' && !disabled) {
			setType(prev => {
				if(prev === 'text') return 'password'
				return 'text'
			})
		}
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value)
		if(!debounce && onChange) onChange({ value: e.target.value, name })
	}

	useEffect(() => {
		if(onChange && debounce && debouncedValue !== _value) {
			onChange({ value: debouncedValue, name })
		}
	}, [onChange, debouncedValue, name, debounce])

	useEffect(() => {
		if(value !== _value) setValue(_value)
	}, [_value])

	return (
		<Root className={className}>
			{(label ?? topRight) && <div className='flex w-full items-end justify-between'>
				{label && <Label htmlFor={id} className='capitalize' style={style}>{label}</Label>}
				{topRight}
			</div>}
			<div className='relative w-full'>
				<Input
					autoFocus={autoFocus}
					autoComplete={autoComplete}
					id={id}
					name={name}
					type={type}
					$error={Boolean(error) || deleteStyle}
					$valid={Boolean(valid)}
					disabled={disabled}
					value={value}
					onChange={handleChange}
					size={size}
					tabIndex={tabIndex}
					ref={inputRef}
					onFocus={onFocus}
					onBlur={onBlur}
					data-form-type={dataFormType}
				/>
				<Show if={Boolean(placeholder)}>
					<Placeholder $visible={!value} size={size}>
						{placeholder}
					</Placeholder>
				</Show>
				<Show if={_type === 'password'}>
					<IconContainer className={disabled ? '' : 'cursor-pointer'} onClick={togglePasswordVisibility}>
						<Show if={type === 'text'}>
							<IconEyeOff />
						</Show>
						<Show if={type === 'password'}>
							<IconEye />
						</Show>
					</IconContainer>
				</Show>
				<Show if={Boolean(endIcon)}>
					<IconContainer htmlFor={id} $right='8px'>{endIcon}</IconContainer>
				</Show>
			</div>
			<Show if={Boolean(message ?? error ?? valid)}>
				<InputMessage $error={Boolean(error)} $valid={Boolean(valid)}>{t(message ?? error ?? valid ?? '')}</InputMessage>
			</Show>
		</Root>
	)
}
