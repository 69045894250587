import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Show } from 'components/Show'
import { ParagraphSemiBold } from 'components/Typography'
import { observer } from 'mobx-react-lite'
import { setCurrentPage } from 'modules/Purchases/actions'
import { editFormStore } from 'modules/Purchases/stores'
import React from 'react'

export const Pagination = observer(() => {
	const { form } = editFormStore
	const length = form.purchase?.pages.length ?? 1
	return (
		<div className='flex items-center'>
			<Show if={length > 1}>
				<IconButton disabled={form.currentPage === 1} onClick={() => { setCurrentPage(form.currentPage - 1) }}><ChevronLeft /></IconButton>
			</Show>
			<ParagraphSemiBold color='var(--color-primary)' className='rounded-md bg-[var(--color-primary-100)] px-8 py-1'>{form.currentPage}{length > 1 ? ` / ${length}` : ''}</ParagraphSemiBold>
			<Show if={length > 1}>
				<IconButton disabled={form.currentPage === form?.purchase?.pages.length ?? form.currentPage} onClick={() => { setCurrentPage(form.currentPage + 1) }}><ChevronRight /></IconButton>
			</Show>
		</div>
	)
})
