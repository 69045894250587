import React from 'react'
import { Form } from './Form'
import { AreaSelectionContextProvider } from 'modules/Purchases/contexts'
import { Extractor } from './Extractor'
import { Modal } from './Modal'
import { Pagination } from 'components/Extractor/Pagination'

export const EditData = () => {
	return (
		<AreaSelectionContextProvider>
			<Modal>
				<div className='flex flex-col gap-[16px] overflow-x-hidden lg:flex-row'>
					<div className='flex w-full flex-[3] flex-col items-center gap-[10px] lg:overflow-y-auto'>
						<Extractor />
						<Pagination />
					</div>
					<div className='flex-[2] pr-[15px] lg:max-w-[600px] lg:overflow-y-auto'>
						<Form />
					</div>
				</div>
			</Modal>
		</AreaSelectionContextProvider>
	)
}
