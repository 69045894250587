import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en'
import fr from './fr'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-http-backend'
import { languages } from 'consts'

export const defaultNS = 'main'

void i18next
	.use(XHR)
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
	.init({
		detection: { convertDetectedLanguage: (lng) => lng.split('-')[0] },
		debug: true,
		resources: {
			en,
			fr
		},
		defaultNS,
		supportedLngs: languages,
		fallbackLng: 'en'
	})
