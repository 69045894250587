import { api } from 'utils'
import { purchasesStore } from '../stores'

export const handleDeletePurchase = async () => {
	purchasesStore.isDeleting = true
	try {
		const id = purchasesStore.deleteModal
		if(id && typeof id === 'string') {
			const res = await api.delete(`/purchases/${id}`)
			if(res.status === 200) {
				purchasesStore.deletePurchase(id)
				purchasesStore.isDeleting = false
				purchasesStore.deleteModal = false
			}
		}
	} catch(err) {
		console.log(err)
	}
	purchasesStore.isDeleting = false
}
