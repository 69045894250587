import { cloneDeep } from 'lodash'
import { DEFAULT_DOCUMENT_TEMPLATE } from '../consts'
import { type IDocumentTemplate, type IPurchase } from '../types'

export const mapTemplateFromPurchase = (purchase: IPurchase): IDocumentTemplate => {
	if(purchase.documentTemplate && typeof purchase.documentTemplate !== 'string') {
		const documentTemplate = cloneDeep(purchase.documentTemplate)
		documentTemplate.values = documentTemplate.values.map(value => {
			if (value.coords) {
				// value.coords.top = value.coords.top - value.coords.height / 2
			}
			return value
		})
		return documentTemplate
	} else return DEFAULT_DOCUMENT_TEMPLATE
}
