import React from 'react'
import { IconWrapper } from '../styled'
import { IconTrash } from 'components/Icons'
import { deleteTvaLine } from 'modules/Purchases/actions'

export const DeleteButton: React.FC<{ index: number }> = ({
	index
}) => {
	return (
		<IconWrapper onClick={() => { deleteTvaLine(index) }} $background='var(--opacity-color-danger-16)' $width='40px' $height='40px'>
			<IconTrash stroke='#EA5455' />
		</IconWrapper>
	)
}
