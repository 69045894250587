import React, { type MouseEvent, useMemo, useState } from 'react'
import { Caption, Card, Paragraph, ParagraphSemiBold, Show, THeader1 } from 'components'
import { IconFile, IconFileChecked } from 'components/Icons'
import { Button, CircularProgress, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IconWrapper, ProcessedIconWrapper, ProcessingIconWrapper } from './styled'
import { DataItem } from '../styled'
import { type IPurchase } from 'modules/Purchases/types'
import { sendReprocessRequest, setDeleteModal, startVerifyData } from 'modules/Purchases/actions'
import { SettingItem, Settings, SettingsClose } from 'components/PageWrapper/SearchBar/styled'
import MoreVert from '@mui/icons-material/MoreVert'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import AutoModeIcon from '@mui/icons-material/AutoMode'

interface PurchaseItemProps {
	purchase: IPurchase
}

export const PurchaseItem: React.FC<PurchaseItemProps> = ({
	purchase
}) => {
	const { t } = useTranslation('purchases')
	const { t: tMain } = useTranslation()
	const [menu, setMenu] = useState<boolean>(false)
	const isReprocessAllowed = useMemo<boolean>(() => {
		const required = ['documentTemplate', 'title', 'totalTTC', 'totalHT', 'totalTVA', 'documentEntity', 'invoiceNumber', 'date', 'processed']
		for (const key in purchase) {
			if(required.includes(key) && [undefined, null, false].includes(purchase[key as keyof typeof purchase] as any)) {
				console.log(purchase.invoiceNumber, key)
				return true
			}
		}
		return false
	}, [purchase])
	const danger = useMemo(() => !purchase.isProcessing && !purchase.processed, [purchase.isProcessing, purchase.processed])
	const totalTVA = useMemo(() => purchase.totalTVA || Number(purchase.tax.reduce((prev, curr) => {
		prev += Number(curr.tva)
		return prev
	}, 0).toFixed(2)), [purchase])
	const totalHT = useMemo(() => purchase.totalHT || Number(purchase.tax.reduce((prev, curr) => {
		prev += Number(curr.ht)
		return prev
	}, 0).toFixed(2)), [purchase])

	const handleOffMenu = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		setMenu(false)
	}
	const handleStartDelete = () => {
		setDeleteModal(purchase._id)
		setMenu(false)
	}
	const handleStartVerifyData = () => {
		startVerifyData(purchase._id)
		setMenu(false)
	}
	const handleSendReprocessRequest = () => {
		if(isReprocessAllowed) {
			sendReprocessRequest(purchase._id).catch(err => { console.log(err) })
		}
		setMenu(false)
	}
	const handleToggleMenu = () => {
		setMenu(prev => !prev)
	}
	const handleOpenFile = () => {
		const url = `${process.env.REACT_APP_BACK_URL}/static${purchase.original}`
		window.open(url, '_blank')
	}
	const handleVerify = () => {
		startVerifyData(purchase._id)
	}

	return (
		<Card $danger={danger} layoutId='purchase'>
			<div className='flex flex-col justify-between gap-5 lg:flex-row lg:gap-3'>
				<div className='flex flex-col gap-[13px]'>
					<div className='flex gap-[10px]'>
						<IconWrapper onClick={handleOpenFile}>
							<IconFile stroke='#fff' />
						</IconWrapper>
						<div className='flex h-full flex-col items-start gap-[6px]'>
							<THeader1 $danger={danger} className='text-left'>{purchase.title || purchase.originalName}</THeader1>
							<Caption>{purchase.invoiceNumber}</Caption>
						</div>
					</div>
					<div className='flex items-center gap-[10px]'>
						<Show if={Boolean(purchase.category)}>
							<Button sx={{ color: 'var(--flowkitblue)' }}>{purchase.category?.title}</Button>
						</Show>
						<Show if={!purchase.category}>
							<Button variant='contained'>{t('toCategorise')}</Button>
						</Show>
						<Show if={!purchase.processed}>
							<Show if={purchase.isProcessing}>
								<ProcessingIconWrapper><CircularProgress size='24px' /></ProcessingIconWrapper>
							</Show>
							<Show if={!purchase.isProcessing}>
								<Button onClick={handleVerify} className='capitalize' variant='contained' color='error'>{t('toVerify')}</Button>
							</Show>
						</Show>
						<Show if={purchase.processed}>
							<ProcessedIconWrapper onClick={handleVerify}><IconFileChecked /></ProcessedIconWrapper>
						</Show>
					</div>
				</div>
				<div className='flex flex-1 items-center justify-between lg:justify-end lg:gap-[45px]'>
					<DataItem>
						<Caption>{t('totalHT')}</Caption>
						<Show if={!purchase.isProcessing}>
							<Show if={danger}>
								<ParagraphSemiBold color='var(--color-danger)'>{t('toVerify')}</ParagraphSemiBold>
							</Show>
							<Show if={!danger}>
								<ParagraphSemiBold>{purchase.paymentWay?.split('_')[0] === 'outcome' ? -totalHT : totalHT + ' €'}</ParagraphSemiBold>
							</Show>
						</Show>
						<Show if={purchase.isProcessing}>
							<div className='h-[22.5px]'><ParagraphSemiBold color='var(--color-info)'>???</ParagraphSemiBold></div>
						</Show>
					</DataItem>
					<DataItem>
						<Caption>{t('tax')}</Caption>
						<Show if={!purchase.isProcessing}>
							<Show if={danger}>
								<ParagraphSemiBold color='var(--color-danger)'>{t('toVerify')}</ParagraphSemiBold>
							</Show>
							<Show if={!danger}>
								<div className='flex flex-col items-end'>
									<Show if={Boolean(purchase.tax.length)}>
										{[...new Set(purchase.tax.map(tax => tax.tax))].map((taxItem: string, i: number) => (
											<ParagraphSemiBold key={`tax-${purchase._id}-tva-${taxItem}-${i}`}>{Number(taxItem) * 100 + ' %'}</ParagraphSemiBold>
										))}
									</Show>
									<Show if={!purchase.tax.length}>
										<ParagraphSemiBold>0%</ParagraphSemiBold>
									</Show>
								</div>
							</Show>
						</Show>
						<Show if={purchase.isProcessing}>
							<div className='h-[22.5px]'><ParagraphSemiBold color='var(--color-info)'>???</ParagraphSemiBold></div>
						</Show>
					</DataItem>
					<DataItem>
						<Caption>{t('tva')}</Caption>
						<Show if={!purchase.isProcessing}>
							<Show if={danger}>
								<ParagraphSemiBold color='var(--color-danger)'>{t('toVerify')}</ParagraphSemiBold>
							</Show>
							<Show if={!danger}>
								<ParagraphSemiBold>{purchase.paymentWay?.split('_')[0] === 'outcome' ? -totalTVA : totalTVA + ' €'}</ParagraphSemiBold>
							</Show>
						</Show>
						<Show if={purchase.isProcessing}>
							<div className='h-[22.5px]'><ParagraphSemiBold color='var(--color-info)'>???</ParagraphSemiBold></div>
						</Show>
					</DataItem>
					<DataItem>
						<Caption>{t('totalTTC')}</Caption>
						<Show if={!purchase.isProcessing}>
							<Show if={danger}>
								<ParagraphSemiBold color='var(--color-danger)'>{t('toVerify')}</ParagraphSemiBold>
							</Show>
							<Show if={!danger}>
								<ParagraphSemiBold>{purchase.paymentWay?.split('_')[0] === 'outcome' ? -purchase.totalTTC : purchase.totalTTC + ' €'}</ParagraphSemiBold>
							</Show>
						</Show>
						<Show if={purchase.isProcessing}>
							<div className='h-[22.5px]'><ParagraphSemiBold color='var(--color-info)'>???</ParagraphSemiBold></div>
						</Show>
					</DataItem>
				</div>
				<div className='relative'>
					<IconButton onClick={handleToggleMenu} sx={{ position: 'relative', alignSelf: 'flex-start', cursor: menu ? 'default' : 'pointer' }}>
						<MoreVert />
					</IconButton>
					<SettingsClose $show={menu} onClick={handleOffMenu} />
					<Settings onClick={e => { e.stopPropagation() }} $show={menu} className='!top-[45px] right-0'>
						<SettingItem onClick={handleStartVerifyData}>
							<Paragraph>{tMain('edit')}</Paragraph>
							<Edit />
						</SettingItem>
						<Show if={isReprocessAllowed}>
							<SettingItem onClick={handleSendReprocessRequest}>
								<Paragraph>{tMain('reprocess')}</Paragraph>
								<AutoModeIcon />
							</SettingItem>
						</Show>
						<SettingItem className='!text-[var(--color-danger-700)]' onClick={handleStartDelete}>
							<Paragraph color='inherit'>{tMain('delete')}</Paragraph>
							<Delete color='inherit' />
						</SettingItem>
					</Settings>
				</div>
			</div>
		</Card>
	)
}
