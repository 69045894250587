import Cookies from 'js-cookie'
import { type NavigateFunction } from 'react-router-dom'
import { api } from 'utils'

export const logout = async (navigate: NavigateFunction) => {
	await api.post('/auth/logout')
	Cookies.remove('user')
	Cookies.remove('sid')
	navigate('/')
}
