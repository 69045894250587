import { Paragraph } from 'components/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const FOOTSTEPS = [
	{ link: '/support', label: 'support' }
]

export const Footer = () => {
	const { t } = useTranslation('')
	return (
		<div className='flex items-center justify-between'>
			<Paragraph>{t('copyright')} v{process.env.REACT_APP_VERSION}</Paragraph>
			<ul>
				{FOOTSTEPS.map(({ link, label }, index) => <li key={`footer-link-${index}`}><Link to={link}><Paragraph>{t(label)}</Paragraph></Link></li>)}
			</ul>
		</div>
	)
}
