import React from 'react'
import { Caption, Display4, Show } from 'components'
import { type IPurchase } from 'modules/Purchases/types'
import { useTranslation } from 'react-i18next'
import { PurchaseItem } from './PurchaseItem'
import { type MotionStyle, motion, usePresence } from 'framer-motion'
import moment from 'moment'
import { toggleCalendarModal } from 'modules/Purchases/actions'

interface DateItemProps {
	days: Array<{ day: number | string, purchases: IPurchase[] }>
	month: number | string
	year: number | string
	children?: React.ReactNode
	showMonth: boolean
}

const transition = { type: 'spring', stiffness: 500, damping: 50, mass: 1 }

export const DateItem: React.FC<DateItemProps> = ({
	days,
	month,
	year,
	children,
	showMonth
}) => {
	const { i18n: { language } } = useTranslation()
	const [isPresent, safeToRemove] = usePresence()
	const style: MotionStyle = {
		position: isPresent ? 'static' : 'absolute'
	}
	const animations = {
		initial: 'out',
		style,
		animate: isPresent ? 'in' : 'out',
		whileTap: 'tapped',
		variants: {
			in: { scaleY: 1, opacity: 1 },
			out: { scaleY: 0, opacity: 0 }
		},
		onAnimationComplete: () => { !isPresent && safeToRemove() },
		transition
	}

	return (
		<motion.div {...animations} className='flex flex-col gap-[16px]' id={`month-${month}-${year}`} >
			<Show if={showMonth}>
				<div className='sticky left-0 top-0 z-[1] flex justify-center bg-[var(--background-body)] px-[50px] py-[26px]'>
					<div className='absolute left-[50px] right-[16px] top-[50%] h-[1px] translate-y-[-50%] bg-[var(--color-dark)]' />
					<Display4 onClick={() => { toggleCalendarModal({ month, year }) }} className='z-[1] cursor-pointer bg-[var(--background-body)] px-[16px] capitalize' color={typeof month === 'string' ? 'var(--color-danger)' : undefined}>
						<Show if={typeof month === 'string'}>{month}</Show>
						<Show if={typeof month === 'number'}>{moment().locale(language).month(month).format('MMMM')}</Show>
					</Display4>
				</div>
			</Show>
			{days.map(({ day, purchases }) => <div key={`day-${day}-${month}-${year}`} id={`day-${day}-${month}-${year}`} className='my-[16px] flex scroll-mt-[112px]'>
				<div className='relative flex h-full min-w-[50px] flex-col items-center justify-start py-[32px]'>
					<div className='absolute left-[50%] top-0 z-[2] h-full w-[1px] translate-x-[-50%] bg-[var(--color-dark)]' />
					<div onClick={() => { toggleCalendarModal({ day, month, year }) }} className='sticky left-0 top-[16px] z-[2] flex w-min cursor-pointer flex-col bg-[var(--background-body)] py-[9px]'>
						<Display4 color={typeof day === 'string' ? 'var(--color-danger)' : 'var(--color-high)'}>
							<Show if={typeof day === 'string'}>{day}</Show>
							<Show if={typeof day === 'number'}>{moment().locale(language).date(Number(day)).format('D')}</Show>
						</Display4>
						<Caption color={typeof month === 'string' ? 'var(--color-danger)' : 'var(--color-high)'}>
							<Show if={typeof month === 'string'}>{month}</Show>
							<Show if={typeof month === 'number'}>{moment().locale(language).month(month).format('MMM').replace('.', '')}</Show>
						</Caption>
					</div>
				</div>
				<div className='flex flex-1 flex-col gap-[16px] pb-[16px] pr-[16px] last-of-type:pb-0'>
					{purchases.map(purchase => <PurchaseItem key={`purchase-${purchase._id}`} purchase={purchase} />)}
				</div>
			</div>)}
		</motion.div>
	)
}
