import styled from 'styled-components'

export const Root = styled.div<{ $noPaddingTop?: boolean }>`
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  height: 100svh;
  width: 260px;
  background: var(--background-card);
  box-shadow: var(--shadow-gray-SM);
  padding: 26px 13px;
  padding-top: ${({ $noPaddingTop }) => $noPaddingTop ? '0' : '26px'};
  position: sticky;
  top: 0;
  @media screen and ( max-width: 1140px ) {
	width: 80px;
  }
`
