import { makeAutoObservable } from 'mobx'
import { type IPurchasesDates, type IPurchase, type IPurchaseInStore } from '../types'
import { mapPurchasesToDates } from '../utils'
import { type IMeta } from 'types/IMeta.type'

const DEFAULT_META = {
	page: 1,
	totalPages: 1,
	perPage: 10
}

export const purchasesStore = makeAutoObservable({
	_isLoading: true as boolean,
	_purchases: [] as IPurchase[],
	_purchasesDates: [] as IPurchasesDates[],
	_lastSearch: '' as string,
	_lastPage: 0 as number,
	_meta: DEFAULT_META as IMeta,
	_deleteModal: false as boolean | string,
	_isDeleting: false as boolean,
	_updateQueue: [] as Array<Partial<IPurchase> | null>,
	_calendarModal: false as boolean | string,
	_scrollTo: undefined as undefined | string,

	get isLoading(): boolean {
		return this._isLoading
	},
	set isLoading(value: boolean) {
		this._isLoading = value
	},
	get purchases(): IPurchase[] {
		return this._purchases
	},
	set purchasesDates(value: IPurchasesDates[]) {
		this._purchasesDates = value
	},
	get purchasesDates(): IPurchasesDates[] {
		return this._purchasesDates
	},
	purchasesSortedDate(resizeFactor: number): IPurchaseInStore[] {
		return mapPurchasesToDates(this._purchases, resizeFactor)
	},
	get lastSearch(): string {
		return this._lastSearch
	},
	set lastSearch(value: string) {
		this._lastSearch = value
	},
	get lastPage(): number {
		return this._lastPage
	},
	set lastPage(value: number) {
		this._lastPage = value
	},
	get meta(): IMeta {
		return this._meta
	},
	set meta(value: IMeta) {
		this._meta = value
	},
	get deleteModal(): boolean | string {
		return this._deleteModal
	},
	set deleteModal(value: boolean | string) {
		this._deleteModal = value
	},
	get isDeleting(): boolean {
		return this._isDeleting
	},
	set isDeleting(value: boolean) {
		this._isDeleting = value
	},
	get calendarModal(): boolean | string {
		return this._calendarModal
	},
	set calendarModal(value: boolean | string) {
		this._calendarModal = value
	},
	get scrollTo(): string | undefined {
		return this._scrollTo
	},
	set scrollTo(value: string | undefined) {
		this._scrollTo = value
	},
	savePurchasesToList(value: IPurchase[], clean?: boolean, atEnd?: boolean) {
		const arr = clean ? value : [...(!atEnd ? [...value] : []), ...this._purchases, ...(atEnd ? [...value] : [])]
		this._purchases = arr
		if(this._updateQueue.length) {
			this._updateQueue.reverse()
			// eslint-disable-next-line @typescript-eslint/no-for-in-array
			for(const _updateIndex in this._updateQueue) {
				const updateIndex = Number(_updateIndex)
				const updatePurchase = this._updateQueue[updateIndex]
				if(updatePurchase) {
					const index = this._purchases.findIndex(purchase => purchase._id === updatePurchase._id)
					if(index > -1) {
						this._purchases[index] = { ...this._purchases[index], ...updatePurchase }
						this._updateQueue.splice(updateIndex, 1, null)
					}
				}
			}
			this._updateQueue = this._updateQueue.filter(update => update !== null)
			this._updateQueue.reverse()
		}
	},
	updatePurchase(id: string, value: Partial<IPurchase>) {
		const index = this._purchases.findIndex(_purchase => _purchase._id === id)
		if(index > -1) {
			this._purchases[index] = { ...this._purchases[index], ...value }
		} else {
			this._updateQueue.push({ _id: id, ...value })
		}
	},
	getPurchaseFromList(id: string): IPurchase | null {
		if(id && this._purchases) return this._purchases.find(purchase => purchase._id === id) ?? null
		else return null
	},
	deletePurchase(id: string) {
		const index = this._purchases.findIndex(purchase => purchase._id === id)
		if(index > -1) {
			this._purchases.splice(index, 1)
		}
	},
	clear() {
		this._isLoading = false as boolean
		this._purchases = [] as IPurchase[]
		this._lastSearch = '' as string
		this._lastPage = 0 as number
		this._meta = DEFAULT_META as IMeta
		this._deleteModal = false as boolean | string
		this._isDeleting = false as boolean
		this._updateQueue = [] as Array<Partial<IPurchase> | null>
		this._calendarModal = false as boolean | string
		this._purchasesDates = [] as IPurchasesDates[]
		this._scrollTo = undefined as undefined | string
	}
}, {}, { autoBind: true })
