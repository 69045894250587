import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconSearch: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
			<circle cx="10.8333" cy="10.8333" r="7.58333" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<circle cx="10.8333" cy="10.8333" r="7.58333" stroke="white" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M22.75 22.75L16.25 16.25" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M22.75 22.75L16.25 16.25" stroke="white" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	)
}
