import React from 'react'
import { DarkMode } from './DarkMode'
import { LanguageSwitch } from './LanguageSwitch'
import { InputSearch } from './InputSearch'
import { Avatar } from './Avatar'
import { Notifications } from './Notifications'
import { Root } from './styled'

export const SearchBar = () => {
	return (
		<Root>
			<InputSearch />
			<div>
				<LanguageSwitch />
				<DarkMode />
				<Notifications />
				<Avatar />
			</div>
		</Root>
	)
}
