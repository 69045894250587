import React, { type ChangeEvent, useState } from 'react'
import { FormControl, MenuItem } from '@mui/material'
import { uniqueId } from 'lodash'
import { Label } from 'components/Typography'
import { Select } from './styled'

export interface InputSelectProps {
	name: string
	value: string
	options: Array<{ label: string, value: string }>
	id?: string
	error?: null | string
	label?: string
	size?: 'small' | 'default' | 'large'
	onChange: ({ value, name }: { value: string, name: string }) => void
}

export const InputSelect: React.FC<InputSelectProps> = ({
	id: _id,
	size = 'default',
	name,
	value,
	options,
	error,
	label,
	onChange
}) => {
	const [id] = useState(_id ?? uniqueId('input-select-'))
	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		onChange({ value: event.target.value, name })
	}

	return (
		<FormControl
			fullWidth
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '4px',
				alignItems: 'flex-start'
			}}
		>
			{label && <Label id={id}>{label}</Label>}
			<Select
				id={id}
				onChange={handleChange}
				value={value}
				size={size}
				$error={Boolean(error)}
			>
				{options.map(({ label, value }, index) => <option key={value + '-' + index} value={value}>{label}</option>)}
			</Select>
		</FormControl>
	)
}
