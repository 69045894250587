import * as React from 'react'
import createTheme from '@mui/material/styles/createTheme'
import MuiThemeProvider from '@mui/material/styles/ThemeProvider'

const localStorageKey = 'mode'

type Mode = 'light' | 'dark' | 'system'
type Theme = 'light' | 'dark'

const getMode = async (): Promise<Mode> => {
	return await new Promise((resolve) =>
		setTimeout(() => {
			resolve(
				(localStorage.getItem(localStorageKey) as Mode | undefined) ?? 'system'
			)
		}, 3000)
	)
}
const saveMode = async (mode: Mode): Promise<void> => { }

export const ThemeContext = React.createContext<{
	mode: Mode
	theme: Theme
	isSafari: boolean
	setMode: (mode: Mode) => void
	iconColor: string
}>({ mode: 'system', theme: 'light', setMode: () => { }, iconColor: '#9b98a3', isSafari: false })

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
	children
}) => {
	const [mode, setMode] = React.useState<Mode>(() => {
		const initialMode =
			(localStorage.getItem(localStorageKey) as Mode | undefined) ?? 'system'
		return initialMode
	})

	React.useState(() => {
		getMode()
			.then(setMode)
			.catch((err) => {
				console.log(err)
			})
	})

	React.useEffect(() => {
		localStorage.setItem(localStorageKey, mode)
		saveMode(mode).catch((err) => {
			console.log(err)
		})
	}, [mode])

	const [theme, setTheme] = React.useState<Theme>(() => {
		if (mode !== 'system') {
			return mode
		}
		const isSystemInDarkMode = matchMedia(
			'(prefers-color-scheme: dark)'
		).matches
		return isSystemInDarkMode ? 'dark' : 'light'
	})

	React.useEffect(() => {
		if (mode !== 'system') {
			setTheme(mode)
			return
		}

		const isSystemInDarkMode = matchMedia('(prefers-color-scheme: dark)')
		setTheme(isSystemInDarkMode.matches ? 'dark' : 'light')

		const listener = (event: MediaQueryListEvent) => {
			setTheme(event.matches ? 'dark' : 'light')
		}
		isSystemInDarkMode.addListener(listener)
		return () => {
			isSystemInDarkMode.removeListener(listener)
		}
	}, [mode])

	React.useEffect(() => {
		document.documentElement.dataset.theme = theme
		const meta = document.getElementById('theme-color') as HTMLMetaElement
		if(meta) meta.content = theme === 'dark' ? '#25293c' : '#847af1'
	}, [theme])

	const muiTheme = createTheme({
		palette: {
			mode: theme,
			primary: {
				100: 'rgba(223, 221, 252, 1)',
				200: 'rgba(192, 186, 248, 1)',
				300: 'rgba(161, 154, 245, 1)',
				400: 'rgba(132, 122, 241, 1)',
				500: 'rgba(104, 92, 238, 1)',
				600: 'rgba(92, 83, 211, 1)',
				700: 'rgba(87, 78, 197, 1)',
				800: 'rgba(82, 72, 184, 1)',
				900: 'rgba(76, 68, 171, 1)'
			},
			error: {
				100: 'rgba(250, 216, 216, 1)',
				200: 'rgba(246, 179, 179, 1)',
				300: 'rgba(240, 141, 142, 1)',
				400: 'rgba(235, 107, 108, 1)',
				500: 'rgba(235, 107, 108, 1)',
				600: 'rgba(205, 67, 67, 1)',
				700: 'rgba(192, 62, 63, 1)',
				800: 'rgba(179, 59, 60, 1)',
				900: 'rgba(165, 55, 56, 1)'
			},
			success: {
				100: 'rgba(206, 242, 222, 1)',
				200: 'rgba(159, 230, 189, 1)',
				300: 'rgba(115, 216, 159, 1)',
				400: 'rgba(73, 204, 129, 1)',
				500: 'rgba(36, 192, 100, 1)',
				600: 'rgba(33, 170, 89, 1)',
				700: 'rgba(31, 159, 83, 1)',
				800: 'rgba(29, 149, 79, 1)',
				900: 'rgba(28, 138, 73, 1)'
			}
		},
		components: {
			MuiCheckbox: {
				styleOverrides: {
					root: {
						'.MuiSvgIcon-root': {
							fill: 'var(--color-gray-400)'
						},
						'&.Mui-checked .MuiSvgIcon-root': {
							fill: 'currentColor'
						},
						'.MuiTypography-root': {
							color: theme === 'light' ? 'rgba(100, 96, 114, 1)' : 'rgba(37, 41, 60, 1)',
							fontSize: '15px',
							fontWeight: '400',
							lineHeight: '22px'
						}
					}
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: 'unset',
						boxShadow: 'none', // 'var(--shadow-gray-SM)',
						padding: '10px 20px',
						fontSize: '15px',
						fontWeight: 500,
						lineHeight: '18px',
						letterSpacing: '0.43px',
						':hover': {
							boxShadow: 'none'
						}
					}
				}

			},
			MuiAccordion: {
				styleOverrides: {
					root: {
						background: 'transparent',
						boxShadow: 'none',
						border: '2px dashed var(--color-divider)'
					}
				}
			}
		}
	})

	return (
		<ThemeContext.Provider
			value={{
				theme,
				mode,
				setMode,
				isSafari: navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple'),
				iconColor: theme === 'light' ? '#646072' : '#adb6df'
			}}
		>
			<MuiThemeProvider theme={muiTheme}>
				{children}
			</MuiThemeProvider>
		</ThemeContext.Provider>
	)
}
