import React from 'react'
import { Root, Content, HeadingTextContainer, Divider, DividerText, SocialButtons, LogoContainer, HeadingText } from './styled'
import { IconFacebook, IconGoogle, IconLogo } from 'components/Icons'
import { Heading4, Paragraph } from 'components'
import { observer } from 'mobx-react-lite'
import { Form } from './Form'
import { useTranslation } from 'react-i18next'

export interface RegisterFormProps {
	variant?: 'card' | 'full'
}

export const RegisterForm: React.FC<RegisterFormProps> = observer(({
	variant = 'card'
}) => {
	const { t: tMain } = useTranslation()
	const { t } = useTranslation('auth')

	return (
		<Root variant={variant}>
			<LogoContainer variant={variant}>
				<IconLogo />
				{variant === 'card' && <HeadingText>{tMain('title')}</HeadingText>}
			</LogoContainer>
			<Content>
				<HeadingTextContainer>
					<Heading4>{t('welcomeRegister')}</Heading4>
					<Paragraph>{t('subheaderRegister')}</Paragraph>
				</HeadingTextContainer>
				<Form />
				<Divider>
					<DividerText>{t('or')}</DividerText>
				</Divider>
				<SocialButtons>
					<IconFacebook />
					<IconGoogle />
				</SocialButtons>
			</Content>
		</Root>
	)
})
