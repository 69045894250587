import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconHome: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
			<path d="M17.4168 7.98416L12.5283 4.18183C11.6457 3.49522 10.4097 3.49522 9.52709 4.18183L4.63759 7.98416C4.04212 8.44723 3.69398 9.15941 3.69434 9.91374V16.5137C3.69434 17.5263 4.51515 18.3471 5.52767 18.3471H16.5277C17.5402 18.3471 18.361 17.5263 18.361 16.5137V9.91374C18.361 9.15933 18.0127 8.44708 17.4168 7.98416" stroke={iconColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M17.4168 7.98416L12.5283 4.18183C11.6457 3.49522 10.4097 3.49522 9.52709 4.18183L4.63759 7.98416C4.04212 8.44723 3.69398 9.15941 3.69434 9.91374V16.5137C3.69434 17.5263 4.51515 18.3471 5.52767 18.3471H16.5277C17.5402 18.3471 18.361 17.5263 18.361 16.5137V9.91374C18.361 9.15933 18.0127 8.44708 17.4168 7.98416" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.6668 13.7499C12.641 14.9718 9.3575 14.9718 7.3335 13.7499" stroke={iconColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.6668 13.7499C12.641 14.9718 9.3575 14.9718 7.3335 13.7499" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	)
}
