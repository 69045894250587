import React, { useContext } from 'react'
import { type IconProps } from '.'
import { ThemeContext } from 'theme'

export const IconUpload: React.FC<IconProps> = ({ stroke }) => {
	const { iconColor } = useContext(ThemeContext)
	if (!stroke) stroke = iconColor
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="29"
			height="29"
			viewBox="0 0 29 29"
			fill="none"
		>
			<path
				d="M4.86023 20.537V22.8703C4.86023 24.159 5.9049 25.2037 7.19356 25.2037H21.1936C22.4822 25.2037 23.5269 24.159 23.5269 22.8703V20.537"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.86023 20.537V22.8703C4.86023 24.159 5.9049 25.2037 7.19356 25.2037H21.1936C22.4822 25.2037 23.5269 24.159 23.5269 22.8703V20.537"
				stroke="white"
				strokeOpacity="0.1"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.36023 11.2037L14.1936 5.37034L20.0269 11.2037"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.36023 11.2037L14.1936 5.37034L20.0269 11.2037"
				stroke="white"
				strokeOpacity="0.1"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.1936 5.37034V19.3703"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.1936 5.37034V19.3703"
				stroke="white"
				strokeOpacity="0.2"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
