import { editFormStore } from '../stores/editFormStore'
import { purchasesStore } from '../stores/purchasesStore'

export const startVerifyData = (purchaseId: string) => {
	editFormStore.open = true
	editFormStore.form.purchaseId = purchaseId
	const template = purchasesStore.purchases.find(purchase => purchase._id === purchaseId)?.documentTemplate
	if(template && typeof template !== 'string' && '_id' in template) {
		editFormStore.form.template = template
	}
}
