import { runInAction } from 'mobx'
import { purchaseFormStore } from '../stores/purchaseFormStore'
import { api } from 'utils'

export const handleFileLoad = async (files: FileList | File[] | null) => {
	purchaseFormStore.isFileLoading = true
	if(files?.length) {
		try {
			const formData = new FormData()
			for(const file of files) {
				if(file) {
					formData.append('file[]', file)
				}
			}
			const res = await api.post('/purchases', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			runInAction(() => {
				purchaseFormStore.acceptedFiles = [...res.data.acceptedFiles, ...purchaseFormStore.acceptedFiles]
				purchaseFormStore.rejectedFiles = [...res.data.rejectedFiles, ...purchaseFormStore.rejectedFiles]
			})
		} catch(err) {
			console.log(err)
		}
	}
	purchaseFormStore.isFileLoading = false
}
