import { makeAutoObservable } from 'mobx'
import { type ILoginForm, type ILoginError } from '../types'
import { type InputChangeProps } from 'types'
import { DEFAULT_LOGIN_ERROR, DEFAULT_LOGIN_FORM } from '../constants'

export const loginStore = makeAutoObservable({
	_form: DEFAULT_LOGIN_FORM as ILoginForm,
	_error: DEFAULT_LOGIN_ERROR as ILoginError,
	_isSubmitting: false as boolean,

	get form(): ILoginForm {
		return this._form
	},
	get error(): ILoginError {
		return this._error
	},
	get isSubmitting(): boolean {
		return this._isSubmitting
	},
	set isSubmitting(value: boolean) {
		this._isSubmitting = value
	},
	changeField(data: InputChangeProps) {
		(this._form as any)[data.name] = data.value
	},
	changeError(data: InputChangeProps) {
		(this._error as any)[data.name] = data.value
	},
	clear() {
		this._form = DEFAULT_LOGIN_FORM as ILoginForm
		this._error = DEFAULT_LOGIN_ERROR as ILoginError
	}
})
