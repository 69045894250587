import { makeAutoObservable } from 'mobx'

type Mode = 'light' | 'dark' | 'system'
type Theme = 'light' | 'dark'

export const appStore = makeAutoObservable({
	_mode:
    (localStorage.getItem('mode') as Mode | undefined) ?? ('system' as Mode),
	_theme: 'light' as Theme,
	_searchString: '' as string,
	_isSearching: false as boolean,

	get mode() {
		return this._mode
	},
	set mode(value: Mode) {
		this._mode = value
	},
	get theme() {
		return this._theme
	},
	set theme(value: Theme) {
		this._theme = value
	},
	get searchString(): string {
		return this._searchString
	},
	set searchString(value: string) {
		this._searchString = value
	},
	get isSearching(): boolean {
		return this._isSearching
	},
	set isSearching(value: boolean) {
		this._isSearching = value
	},
	toggleDarkMode() {
		if (this._theme === 'light') this._theme = 'dark'
		else this._theme = 'light'
	}
})
