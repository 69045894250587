import { Caption, Card, Paragraph } from 'components'
import React from 'react'
import { DataItem } from '../styled'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { purchasesStore } from 'modules/Purchases/stores'

export const PurchasesSummary = observer(() => {
	const { t } = useTranslation('purchases')
	const { purchases } = purchasesStore

	const totalHT = purchases.reduce((prev, curr) => {
		const totalHT = curr.totalHT || curr.tax.reduce((prev, curr) => {
			prev += Number(curr.ht)
			return prev
		}, 0)
		const toAdd = !isNaN(Number(totalHT)) ? Number(totalHT) : 0
		if(curr.paymentWay?.split('_')[0] === 'income') {
			prev += toAdd
		}
		if(curr.paymentWay?.split('_')[0] === 'outcome') {
			prev -= toAdd
		}
		return prev
	}, 0).toFixed(2)
	const totalTVA = purchases.reduce((prev, curr) => {
		const totalTVA = curr.totalTVA || curr.tax.reduce((prev, curr) => {
			prev += Number(curr.tva)
			return prev
		}, 0)
		const toAdd = !isNaN(Number(totalTVA)) ? Number(totalTVA) : 0
		if(curr.paymentWay?.split('_')[0] === 'income') {
			prev += toAdd
		}
		if(curr.paymentWay?.split('_')[0] === 'outcome') {
			prev -= toAdd
		}
		return prev
	}, 0).toFixed(2)
	const totalTTC = purchases.reduce((prev, curr) => {
		const toAdd = !isNaN(Number(curr.totalTTC)) ? Number(curr.totalTTC) : 0
		if(curr.paymentWay?.split('_')[0] === 'income') {
			prev += toAdd
		}
		if(curr.paymentWay?.split('_')[0] === 'outcome') {
			prev -= toAdd
		}
		return prev
	}, 0).toFixed(2)

	return (
		<Card className='flex justify-between border-t-2 border-[var(--color-primary)]'>
			<DataItem>
				<Caption>{t('transactions')}</Caption>
				<Paragraph $weight='semi-bold'>{purchases.length}</Paragraph>
			</DataItem>
			<DataItem>
				<Caption>{t('totalHT')}</Caption>
				<Paragraph $weight='semi-bold'>{totalHT} €</Paragraph>
			</DataItem>
			<DataItem>
				<Caption>{t('totalTVA')}</Caption>
				<Paragraph $weight='semi-bold'>{totalTVA} €</Paragraph>
			</DataItem>
			<DataItem>
				<Caption>{t('totalTTC')}</Caption>
				<Paragraph $weight='semi-bold'>{totalTTC} €</Paragraph>
			</DataItem>
		</Card>
	)
})
