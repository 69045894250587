import { type MotionStyle, motion, usePresence } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const Root = styled(motion.div)<{ $danger?: boolean }>`
	border-radius: 10px;
	background: var(--background-card);
	padding: 24px;
	box-shadow: ${({ $danger }) => $danger ? 'var(--shadow-card-danger)' : 'unset'};
`

interface CardProps {
	children: React.ReactNode
	className?: string
	layoutId?: string
	$danger?: boolean
}

const transition = { type: 'spring', stiffness: 500, damping: 50, mass: 1 }

export const Card: React.FC<CardProps> = ({
	children,
	className,
	layoutId,
	$danger
}) => {
	const [isPresent, safeToRemove] = usePresence()
	const style: MotionStyle = {
		position: isPresent ? 'static' : 'absolute'
	}
	const animations = {
		initial: 'out',
		style,
		animate: isPresent ? 'in' : 'out',
		whileTap: 'tapped',
		variants: {
			in: { scaleY: 1, opacity: 1 },
			out: { scaleY: 0, opacity: 0 }
		},
		onAnimationComplete: () => { !isPresent && safeToRemove() },
		transition
	}
	return (
		<Root {...animations} $danger={$danger} className={className}>
			{children}
		</Root>
	)
}
