import { cloneDeep } from 'lodash'
import { type IPurchaseInStore, type IPurchase } from '../types'
import moment from 'moment'

const applyResizeFactor = (purchase: IPurchase, resizeFactor: number): IPurchase => {
	if(purchase.documentTemplate && typeof purchase.documentTemplate !== 'string') {
		const resizedDocumentTemplate = {
			...purchase.documentTemplate,
			values: purchase.documentTemplate.values.map(value => {
				if(value.coords) {
					return {
						...value,
						coords: {
							page: value.coords.page,
							left: value.coords.left * resizeFactor,
							top: value.coords.top * resizeFactor,
							width: value.coords.width * resizeFactor,
							height: value.coords.height * resizeFactor
						}
					}
				}
				return value
			})
		}
		purchase.documentTemplate = resizedDocumentTemplate
	}
	return purchase
}

export const mapPurchasesToDates = (purchases: IPurchase[], resizeFactor: number): IPurchaseInStore[] => {
	const purchasesCloned = cloneDeep(purchases)
	const _purchases = purchasesCloned.map(purchase => {
		const modified = applyResizeFactor(purchase, resizeFactor)
		return modified
	}).sort((a, b) => moment(a.date).isBefore(moment(b.date)) ? 1 : -1)
	let arr = [] as IPurchaseInStore[]
	for(const purchase of _purchases) {
		if(purchase.date) {
			const day = moment(purchase.date).get('date')
			const month = moment(purchase.date).get('month')
			const year = moment(purchase.date).get('year')
			const index = arr.findIndex(item => item.month === month && item.year === year)
			if(index > -1) {
				const i = arr[index].days.findIndex(item => item.day === day)
				if(i > -1) {
					arr[index].days[i].purchases.push(purchase)
				} else {
					arr[index].days.push({
						day,
						purchases: [purchase]
					})
				}
			} else {
				arr.push({
					month,
					year,
					days: [{ day, purchases: [purchase] }]
				})
			}
		} else {
			const index = arr.findIndex(item => item.month === '???' && item.year === '???')
			if(index > -1) {
				arr[index].days[0].purchases.push(purchase)
			} else {
				arr.push({
					month: '???',
					year: '???',
					days: [{ day: '?', purchases: [purchase] }]
				})
			}
		}
	}
	arr = arr.sort((a, b) => {
		if(a.month === '???' && a.year === '???') return 1
		else if(b.month === '???' && b.year === '???') return 1
		else if(a.year < b.year) return 1
		else if(a.month < b.month) return 1
		else return -1
	})
	for(const item of arr) {
		item.days = item.days.sort((a, b) => a.day < b.day ? 1 : -1)
		for(const day of item.days) {
			day.purchases = day.purchases.sort((a, b) => moment(a.date).isBefore(moment(b.date)) ? 1 : -1)
		}
	}

	return arr
}
