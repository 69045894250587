import { Paragraph } from 'components/Typography'
import React from 'react'
import { Link, useLocation, type To } from 'react-router-dom'
import styled from 'styled-components'

interface CommonProps {
	notifications?: number
	label: string
	icon: React.ReactNode
}

type ConditionalProps = {
	children?: React.ReactNode
	to?: never
} | {
	children?: never
	to?: To

}

type MenuItemProps = CommonProps & ConditionalProps

const Root = styled.div<{ $active?: boolean }>`
	cursor: pointer;
	transition: all 0.2s ease;
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px 16px;
	border-radius: 6px;
	box-shadow: ${({ $active }) => $active ? 'var(--shadow-menu-active-shadow)' : ''};
	background: ${({ $active }) => $active ? 'linear-gradient(72deg, #7367F0 22.16%, rgba(115, 103, 240, 0.70) 76.47%)' : ''};
	color: var(${({ $active }) => $active ? '--color-white' : '--typography-body'});
	${({ $active }) => $active ? 'svg > path { stroke: var(--color-white); }' : ''}
	&:hover {
		${({ $active }) => $active
		? `
			box-shadow: var(--shadow-primary-default);
		`
		: `
			box-shadow: unset;
			color: var(--typography-heading);
			background: var(--opacity-color-menu-hover);
		`}
	}
	@media screen and ( max-width: 1140px ) {
		> p, > div {
			display: none;
		}
	}
`

const NotificationDiv = styled.div`
	background: var(--color-danger);
	padding: 4px 10px;
	border-radius: 14px;
`

const _MenuItem: React.FC<MenuItemProps & { $active: boolean }> = ({ $active, icon, label, notifications, children }) => <Root $active={$active}>
	{icon}
	<Paragraph color='inherit'>{label}</Paragraph>
	{notifications && <NotificationDiv>{notifications}</NotificationDiv>}
</Root>

export const MenuItem: React.FC<MenuItemProps> = (props) => {
	const location = useLocation()
	const { notifications, children, to, label, icon } = props
	const $active = (location.pathname === to) || (location.pathname !== '/' && String(to).includes(location.pathname))
	if(children) {
		return (
			<_MenuItem $active={$active} icon={icon} notifications={notifications} label={label}>
				{children}
			</_MenuItem>
		)
	}
	return (
		<Link to={to ?? ''}>
			<_MenuItem $active={$active} icon={icon} notifications={notifications} label={label} />
		</Link>
	)
}
