import React, { createContext, useEffect, useRef, useState } from 'react'
import { type IPurchase, type AreaSelectionOnChangeType } from '../types'

export interface IAreaSelectionContext {
	style: {
		top: number
		left: number
		width: number
		height: number
	}
	setStyle: React.Dispatch<React.SetStateAction<{
		top: number
		left: number
		width: number
		height: number
	}>>
	isSelectionActive: boolean
	setIsSelectionActive: React.Dispatch<React.SetStateAction<boolean>>
	display: 'block' | 'none'
	setDisplay: React.Dispatch<React.SetStateAction<'block' | 'none'>>
	name: null | string
	setName: React.Dispatch<React.SetStateAction<null | string>>
	_coords: React.MutableRefObject<{
		x1: number
		y1: number
		x2: number
		y2: number
	}>
	containerRef: React.MutableRefObject<HTMLDivElement | null>
	onChange: React.MutableRefObject<AreaSelectionOnChangeType | null>
	page: React.MutableRefObject<number>
	index: React.MutableRefObject<number>
	canvasRef: React.MutableRefObject<null | HTMLCanvasElement>
	purchase: React.MutableRefObject<null | IPurchase>
	resizeFactor: React.MutableRefObject<null | number>
	currenTemplateValueFocused: string
	setCurrentTemplateValueFocused: React.Dispatch<React.SetStateAction<string>>
}

export const AreaSelectionContext = createContext<IAreaSelectionContext | null>(null)

export const AreaSelectionContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [style, setStyle] = useState<{
		top: number
		left: number
		width: number
		height: number
	}>({ top: 0, left: 0, width: 0, height: 0 })
	const [name, setName] = useState<null | string>(null)
	const [isSelectionActive, setIsSelectionActive] = useState(false)
	const [display, setDisplay] = useState<'block' | 'none'>('none')
	const _coords = useRef({
		x1: 0,
		y1: 0,
		x2: 0,
		y2: 0
	})
	const containerRef = useRef<null | HTMLDivElement>(null)
	const onChange = useRef<null | AreaSelectionOnChangeType>(null)
	const page = useRef<number>(1)
	const index = useRef<number>(0)
	const canvasRef = useRef<null | HTMLCanvasElement>(null)
	const purchase = useRef<null | IPurchase>(null)
	const resizeFactor = useRef<null | number>(null)
	const [currenTemplateValueFocused, setCurrentTemplateValueFocused] = useState<string>('')

	useEffect(() => {
		return () => {
			setStyle({ top: 0, left: 0, width: 0, height: 0 })
			setIsSelectionActive(false)
			setDisplay('none')
			containerRef.current = null
			_coords.current = {
				x1: 0,
				y1: 0,
				x2: 0,
				y2: 0
			}
		}
	}, [])
	return (
		<AreaSelectionContext.Provider
			value={{
				style,
				setStyle,
				isSelectionActive,
				setIsSelectionActive,
				display,
				setDisplay,
				name,
				setName,
				_coords,
				containerRef,
				onChange,
				page,
				canvasRef,
				index,
				purchase,
				resizeFactor,
				currenTemplateValueFocused,
				setCurrentTemplateValueFocused
			}}
		>
			{children}
		</AreaSelectionContext.Provider>
	)
}
