import React from 'react'
import { type IconProps } from '.'

export const IconGoogle: React.FC<IconProps> = () => {
	return (
		<svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
			<rect width="38" height="38" rx="6" fill="#DB4437" fillOpacity="0.16"/>
			<path d="M26.6883 17.5011C24.1789 17.4999 21.6694 17.5005 19.16 17.5008C19.1603 18.5416 19.1588 19.5825 19.1606 20.623C20.6139 20.6227 22.0672 20.6224 23.5202 20.623C23.3518 21.6204 22.759 22.5325 21.9185 23.094C21.3901 23.4493 20.783 23.6799 20.1571 23.7895C19.5272 23.897 18.8763 23.9108 18.2479 23.7836C17.6089 23.6561 16.999 23.3899 16.4668 23.0146C15.616 22.4168 14.9667 21.5422 14.6333 20.5579C14.2924 19.5568 14.2899 18.4428 14.6348 17.4426C14.8742 16.7377 15.2726 16.0865 15.7959 15.5566C16.4415 14.8957 17.2808 14.4233 18.1851 14.2295C18.9594 14.0642 19.7758 14.0958 20.5339 14.3251C21.1782 14.5208 21.7722 14.8738 22.2588 15.3385C22.7506 14.8494 23.2399 14.3576 23.7308 13.8677C23.9883 13.604 24.2589 13.3518 24.5082 13.0809C23.7627 12.391 22.8887 11.8363 21.9338 11.4857C20.2146 10.853 18.2757 10.8396 16.5449 11.4386C14.5945 12.1064 12.9337 13.565 12.0129 15.4085C11.6923 16.0438 11.4583 16.7218 11.3177 17.4193C10.9639 19.1569 11.2105 21.0095 12.012 22.5922C12.5329 23.6252 13.2796 24.5435 14.1855 25.2635C15.0401 25.945 16.0363 26.4483 17.0924 26.7293C18.4251 27.0867 19.8437 27.0786 21.1851 26.7733C22.3975 26.4943 23.5446 25.915 24.4604 25.0694C25.4285 24.1798 26.119 23.0077 26.4846 21.7482C26.8833 20.3743 26.9383 18.9075 26.6883 17.5011Z" fill="#DB4437"/>
		</svg>
	)
}
