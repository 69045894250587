import React from 'react'
import { type IconProps } from '.'

export const IconLogo: React.FC<IconProps> = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="35" height="24" viewBox="0 0 35 24" fill="none">
			<path fillRule="evenodd" clipRule="evenodd" d="M0.501836 0.3125V7.59485C0.501836 7.59485 0.358498 9.88783 2.60473 11.8288L15.0469 23.6837L21.5172 23.6005L20.4794 10.8126L18.0261 7.93369L10.3154 0.3125H0.501836Z" fill="#7367F0"/>
			<path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M8.67969 17.7762L13.8027 3.75179L18.089 8.02198L8.67969 17.7762Z" fill="#161616"/>
			<path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M9.08203 17.2249L15.3129 5.24237L18.1211 8.05253L9.08203 17.2249Z" fill="#161616"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M8.75781 17.6914L25.6339 0.3125H34.4991V7.62657C34.4991 7.62657 34.3144 10.0645 33.0743 11.3686L21.5179 23.6875H15.0487L8.75781 17.6914Z" fill="#7367F0"/>
		</svg>
	)
}
