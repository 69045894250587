import React, { useState } from 'react'
import { getUser, logout } from 'actions'
import { Avatar as AvatarComponent, Paragraph } from 'components'
import styled from 'styled-components'
import { IconLogout } from 'components/Icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SettingItem, Settings, SettingsClose } from './styled'

const Root = styled.div`
	position: relative;
`

export const Avatar = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const user = getUser()
	const [show, setShow] = useState(false)

	const handleToggleShow = () => {
		setShow(prev => !prev)
	}

	const handleOffShow = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		setShow(false)
	}

	const handleLogout = async () => {
		await logout(navigate)
	}

	return (
		<Root onClick={handleToggleShow}>
			<SettingsClose $show={show} onClick={handleOffShow} />
			<AvatarComponent src={user?.avatar} />
			<Settings className='right-[-24px]' onClick={e => { e.stopPropagation() }} $show={show}>
				<SettingItem className='!min-w-[140px]' onClick={handleLogout}>
					<Paragraph>{t('logout')}</Paragraph>
					<IconLogout />
				</SettingItem>
			</Settings>
		</Root>
	)
}
