import React, { useContext } from 'react'
import { Card, Heading3 } from 'components'
import { useTranslation } from 'react-i18next'
import { type ButtonTypeMap, Button as MuiButton, CircularProgress, IconButton } from '@mui/material'
import { IconPlus } from 'components/Icons'
import { type DefaultComponentProps } from '@mui/material/OverridableComponent'
import { openModal } from 'modules/Purchases/actions'
import { observer } from 'mobx-react-lite'
import { purchaseFormStore } from 'modules/Purchases/stores/purchaseFormStore'
import TuneIcon from '@mui/icons-material/Tune'
import { ThemeContext } from 'theme'

const Button = (props: DefaultComponentProps<ButtonTypeMap>) => <MuiButton variant='contained' sx={{ textTransform: 'uppercase' }} {...props} startIcon={props.startIcon ?? <IconPlus stroke='white' />}>{props.children}</MuiButton>

export const Header = observer(() => {
	const { t } = useTranslation('purchases')
	const { iconColor } = useContext(ThemeContext)
	const { isFileLoading } = purchaseFormStore
	return (
		<Card>
			<div className='flex items-center justify-between gap-[16px]'>
				<Heading3 className='mr-auto'>{t('purchases')}</Heading3>
				<IconButton sx={{ color: iconColor }}><TuneIcon color='inherit' /></IconButton>
				<Button onClick={openModal} startIcon={isFileLoading ? <CircularProgress size='25px' /> : undefined} disabled={isFileLoading}>{t('addPurchase')}</Button>
			</div>
		</Card>
	)
})
