import React, { type MouseEvent } from 'react'
import { ExpandMore, PriorityHigh } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { Label, ParagraphSemiBold } from 'components'
import { observer } from 'mobx-react-lite'
import { startVerifyData } from 'modules/Purchases/actions'
import { purchaseFormStore } from 'modules/Purchases/stores'

export const RejectedFiles = observer(() => {
	const { rejectedFiles } = purchaseFormStore

	const handleOpenVerifyData = (e: MouseEvent<HTMLDivElement>) => {
		const { id } = e.currentTarget
		if(id) startVerifyData(id)
	}
	if(rejectedFiles.length) {
		return (<div className='w-full'>
			<Accordion sx={{ width: '100%', borderColor: 'var(--color-danger-400)' }} defaultExpanded>
				<AccordionSummary
					expandIcon={<ExpandMore color='error' />}
				>
					<ParagraphSemiBold color='var(--color-danger-500)'>{rejectedFiles.length} REJECTED FILES</ParagraphSemiBold>
				</AccordionSummary>
				<AccordionDetails sx={{ textAlign: 'left', maxHeight: 'calc(100svh / 8)', overflowY: 'auto' }}>
					<div className='flex flex-col items-start gap-2'>
						{rejectedFiles.map((file, index) => <div key={`rejected-file-${index}`} id={file.id} className='inline-flex cursor-pointer items-center justify-start' onClick={handleOpenVerifyData}>
							<PriorityHigh color='error' />
							<Label className='cursor-pointer' color='var(--color-danger-400)'>{file.originalName.length > 69 ? file.originalName.substring(0, 69) + ' ... .' + file.originalName.split('.').pop() : file.originalName}</Label>
						</div>)}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>)
	}
})
